import "./styles.css"
import Online_Shop from "../../assets/images/landing/2023/Component1.png"
import Online_MakroClick from "../../assets/images/shop-logo/Online_Makro.png"
import Online_All from "../../assets/images/shop-logo/All_Online.png"
import Online_Lotus from "../../assets/images/shop-logo/Online_Lotus.png"
import Online_CPFreshmart from "../../assets/images/shop-logo/Online_CP-Freshmart.png"
import Online_Tops from "../../assets/images/shop-logo/Online_TopsOnline.png"
import Online_Gourmet from "../../assets/images/shop-logo/Online_Gourmet.png"

// import Offline_HomeFreshmart from "../../assets/images/shop-logo/Offline_HomeFreshMart.png"
// import Offline_BigC from "../../assets/images/shop-logo/Offline_Big C.png"
// import Offline_GoldenPlace from "../../assets/images/shop-logo/Offline_GoldenPlace.png"
// import Offline_Lotus from "../../assets/images/shop-logo/Offline_Lotus.png"
// import Offline_Makro from "../../assets/images/shop-logo/Offline_Makro.png"
// import Offline_Gourmet from "../../assets/images/shop-logo/Offline_Gourmet.png"
// import Offline_DearTommy from "../../assets/images/shop-logo/Offline_DearTommy.png"
// import Offline_CPFreshMart from "../../assets/images/shop-logo/CPFreshMart.png"
// import Offline_MaxValu from "../../assets/images/shop-logo/Offline_MaxValu.png"
// import Offline_Tops from "../../assets/images/shop-logo/Offline_Tops.png"

import Offline_Makro from "../../assets/images/2023/logo/makro.png"
import Offline_Lotus from "../../assets/images/2023/logo/lotus.png"
import Offline_CPFreshMart from "../../assets/images/2023/logo/freshmart.png"
import Offline_BigC from "../../assets/images/2023/logo/bigc.png"
import Offline_HomeFreshmart from "../../assets/images/2023/logo/homefresh.png"
import Offline_Gourmet from "../../assets/images/2023/logo/gourmel.png"
import Offline_Tops from "../../assets/images/2023/logo/tops.png"
import Offline_MaxValu from "../../assets/images/2023/logo/maxvalue.png"
import Offline_GoldenPlace from "../../assets/images/2023/logo/goldenplace.png"
import Offline_DearTommy from "../../assets/images/2023/logo/dear.png"
import Offline_RimpingSupermarket from "../../assets/images/2023/logo/rimping.png"


import Offline_Shopee from "../../assets/images/landing/2023/shoppee.png"
import Offline_Lazada from "../../assets/images/landing/2023/lazada.png"
import Cloud_left from "../../assets/images/landing/2023/CLOUDL.png"
import Cloud_right from "../../assets/images/landing/2023/CLOUDR.png"
import fire from "../../assets/images/landing/2023/fire.png"
// import Offline_RimpingSupermarket from "../../assets/images/shop-logo/Offline_RimpingSupermarket.png"
import Offline_TopsOnline from "../../assets/images/shop-logo/Offline_TopsOnline.png"
import { Link } from "react-router-dom"
import Underline from "../Light/light"
import CampaignKV from "../../assets/images/landing/CP-CNY2022_KV-campaign.png"
import bg from "../../assets//images/landing/bg.png"

const Shop = () => {
    return (
        <div className="container-fluid p-0 w-100">
            <div className="wrapper-shop-online container-fluid shopnow pt-5">
                <div className="">
                    <div className="fire">
                        <img src={fire} alt="สั่งซื้อซีพีของไหว้ ที่ Lotus's Online" className="fl-left" />
                        <img src={fire} alt="สั่งซื้อซีพีของไหว้ ที่ Lotus's Online" className="fl-right" />
                    </div>
                </div>
                <div className="d-flex justify-content-center section-title">
                    <div className="shadow-first-section-shop" id="section-1">
                        <h4 className="text-gold title-mobile text-center text-head-shop">สั่งซื้อออนไลน์ได้แล้ววันนี้</h4>
                    </div>
                </div>
                <div className="bg-gold">
                    <div className="container d-flex h-100 justify-content-center flex-wrap ">
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-MakroClick")}>
                            <img className="d-block shop-logo" src={Online_MakroClick} alt="สั่งซื้อซีพีของไหว้ ที่ MakroClick" style={{ width: '70%' }} />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ MakroClick" />
                            </span>
                        </Link>
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-Lotus")}>
                            <img className="d-block shop-logo" src={Online_Lotus} alt="สั่งซื้อซีพีของไหว้ ที่ Lotus's Online" />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ Lotus's Online" />
                            </span>
                        </Link>
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-CPFreshmart")}>
                            <img className="d-block shop-logo" src={Offline_CPFreshMart} alt="สั่งซื้อซีพีของไหว้ ที่ CP Freshmart" />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ CP Freshmart" />
                            </span>
                        </Link>
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-All")}>
                            <img className="d-block shop-logo" src={Online_All} alt="สั่งซื้อซีพีของไหว้ ที่ CP Freshmart" />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ CP Freshmart" />
                            </span>
                        </Link>
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-BigC")}>
                            <img style={{ width: '60%' }} className="d-block shop-logo" src={Offline_BigC} alt="สั่งซื้อซีพีของไหว้ ที่ BigC Online" />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ BigC Online" />
                            </span>
                        </Link>
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-GourmetMarket")}>
                            <img className="d-block shop-logo" src={Online_Gourmet} alt="สั่งซื้อซีพีของไหว้ ที่ Gourmet Market" style={{ width: '60%' }} />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ Gourmet Market" />
                            </span>
                        </Link>
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-TopsOnline")}>
                            <img className="d-block shop-logo" src={Offline_TopsOnline} alt="สั่งซื้อซีพีของไหว้ ที่ Tops Online" style={{ width: '70%' }} />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ Tops Online" />
                            </span>
                        </Link>
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-Shopee")}>
                            <img className="d-block shop-logo" src={Offline_Shopee} alt="สั่งซื้อซีพีของไหว้ ที่ Shopee" />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ Shopee" />
                            </span>
                        </Link>
                        <Link className="shop-item" target="_blank" to={encodeURI("/OnlineShop-Lazada")}>
                            <img className="d-block shop-logo" src={Offline_Lazada} alt="สั่งซื้อซีพีของไหว้ ที่ Lazada" />
                            <span className="btn-shop-now align-items-center">
                                <img src={Online_Shop} alt="สั่งซื้อซีพีของไหว้ ที่ Lazada" />
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="all-country wrapper-shop-offline">


                <div className="d-flex justify-content-center section-title offlineShop-w">
                    <h1 className="text-white">
                        <img className="cloud" src={Cloud_left} />
                        หาซื้อได้ที่ห้างสรรพสินค้าชั้นนำทั่วประเทศ
                        <img className="cloud" src={Cloud_right} />
                    </h1>
                </div>

                <div className="d-flex justify-content-center section-title offlineShop-m">
                    <img className="cloud" src={Cloud_left} />
                    <h1 className="text-white">
                        หาซื้อได้ที่ห้างสรรพสินค้า<br />
                        ชั้นนำทั่วประเทศ
                    </h1>
                    <img className="cloud" src={Cloud_right} />
                </div>


                <div className="wrapper-shop-logos d-flex justify-content-center flex-wrap align-items-center">
                    <div><img className="w-100 pd-logo" src={Offline_Makro} /></div>
                    <div><img className="w-100 pd-logo" src={Offline_Lotus} /></div>
                    <div><img className="w-100 pd-logo" src={Offline_CPFreshMart} /></div>
                    <div><img className="w-100 pd-logo" src={Offline_BigC} /></div>
                    <div><img className="w-100 pd-logo" src={Offline_HomeFreshmart} /></div>
                    <div><img className="w-100 pd-logo-s" src={Offline_Gourmet} /></div>
                    <div><img className="w-100 pd-logo" src={Offline_Tops} /></div>
                    <div><img className="w-100 pd-logo" src={Offline_MaxValu} /></div>
                    <div><img className="w-100 pd-logo mx-logo" src={Offline_GoldenPlace} /></div>
                    <div><img className="w-100 pd-logo" src={Offline_DearTommy} /></div>
                    <div><img className="w-100 pd-log-s-s" src={Offline_RimpingSupermarket} /></div>

                </div>
            </div>

            {/* <div className="wrapper-campaign col-12 position-relative section-title">
                <h1 className="color-yellow">
                    กิจกรรมลุ้นเฮง แห่งปีเสือทอง 2565
                    <Underline />
                </h1>
                <h2 className="text-center text-white pt-3">
                    สายมู ไหว้เจ้าหนุนดวง ลุ้นรับ ดับเบิ้ลเฮง จากของไหว้ซีพี
                </h2>
                <h4 className="text-center text-white pt-2">
                    เพียงนำใบเสร็จซื้อของไหว้ซีพีจากห้างสรรพสินค้า ยอดครบ 1,500 บาทขึ้นไป
                </h4>
                <h4 className="text-center text-white">
                    มาลงทะเบียนลุ้นดูดวงกับหมอดูชื่อดัง พร้อมแนะนำวิธีการเสริมมงคลชีวิต
                </h4>
                <div className="wrapper-campaign-kv col-lg-10 col-12 mx-auto">
                    <img src={CampaignKV} alt="กิจกรรมลุ้นเฮง แห่งปีเสือทอง 2565 สายมู ไหว้เจ้าหนุนดวง ลุ้นรับ ดับเบิ้ลเฮง จากของไหว้ซีพี" className="w-100" />
                    <div className="wrapper-campaign-button d-flex justify-content-center w-100">
                        <Link to="/campaign" className="btn-register">
                            ลงทะเบียนลุ้นเฮง
                        </Link>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Shop
