export const mapMonth = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'
]

export const mapMonthObject = {
    month01: 'มกราคม',
    month02: 'กุมภาพันธ์',
    month03: 'มีนาคม',
    month04: 'เมษายน',
    month05: 'พฤษภาคม',
    month06: 'มิถุนายน',
    month07: 'กรกฎาคม',
    month08: 'สิงหาคม',
    month09: 'กันยายน',
    month10: 'ตุลาคม',
    month11: 'พฤศจิกายน',
    month12: 'ธันวาคม'
}

export const mapDay = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
]

export const margets = [
    "Makro (แม็คโคร)",
    "Lotus’s (โลตัส)",
    "CP Freshmart (ซีพี เฟรชมาร์ท)",
    "7-Eleven (เซเว่น อีเลฟเว่น)",
    "Big C (บิ๊กซี)",
    "Gourmet Market (กูร์เมต์ มาร์เก็ต)",
    "Tops (ท็อปส์)",
    "MaxValu (แม็กซ์แวลู)",
    "Dear Tummy (เดียร์ ทัมมี่ )",
    "Golden Place (โกลเด้น เพลซ )",

]