import item1 from "../../assets/images/2024/product/mini1.webp"
import item2 from "../../assets/images/2024/product/mini2.webp"
import item3 from "../../assets/images/2024/product/mini3.webp"
import item4 from "../../assets/images/2024/product/mini4.webp"
import item5 from "../../assets/images/2024/product/mini5.webp"
import item6 from "../../assets/images/2024/product/mini6.webp"
import item7 from "../../assets/images/2024/product/mini7.webp"
import item8 from "../../assets/images/2024/product/mini8.webp"
import item9 from "../../assets/images/2024/product/mini9.webp"
import item10 from "../../assets/images/2024/product/mini10.webp"
import item11 from "../../assets/images/2024/product/mini11.webp"
export const productsdataMinipack = [
    {
        group: [

            {
                title: "ซีพี เนื้อเป็ดพะโล้ต้นตำรับ",
                img: item1
            },
            {
                title: "ซีพี เนื้อเป็ดย่างเครื่องเทศ",
                title2: "สไตล์ฮ่องกง",
                img: item2
            },
            {
                title: "ซีพี ขาหมูคูโรบูตะ",
                title2: "ในน้ำพะโล้",
                img: item3
            }
        ]
    },
    {
        group: [
            {
                title: "ซีพี นักเก็ตไก่",
                img: item4
            },
            {
                title: "ซีพี ไก่จ๊อ รสต้นตำรับ",
                img: item5
            },
            {
                title: "ซีพี เกี๊ยวกุ้งจักรพรรดิ",
                img: item6
            },

        ]
    },
    {
        group: [

            {
                title: "ซีพี เกี๊ยวกุ้งจักรพรรดิ",
                img: item7
            },
            {
                title: "ซีพี เกี๊ยวกุ้งรสต้มยำ",
                img: item8
            },
            {
                title: "ซีพี เกี๊ยวหมูลุยสวน",
                img: item9
            },
        ]
    },
    {
        group: [

            {
                title: "ซีพี เกี๊ยวซ่าหมู",
                img: item10
            },
            {
                title: "ซีพี เกี๊ยวหมูซอสญี่ปุ่น",
                img: item11
            },
        ]
    },
]

export const productsdataMinipackInMobileSize = [
    {
        group: [

            {
                title: "ซีพี เนื้อเป็ดพะโล้ต้นตำรับ",
                img: item1
            },
            {
                title: "ซีพี เนื้อเป็ดย่างเครื่องเทศ",
                title2: "สไตล์ฮ่องกง",
                img: item2
            },

        ]
    },
    {
        group: [
            {
                title: "ซีพี ขาหมูคูโรบูตะ",
                title2: "ในน้ำพะโล้",
                img: item3
            },
            {
                title: "ซีพี นักเก็ตไก่",
                img: item4
            },


        ]
    },
    {
        group: [
            {
                title: "ซีพี ไก่จ๊อ รสต้นตำรับ",
                img: item5
            },
            {
                title: "ซีพี ไก่จ๊อ รสต้นตำรับ",
                img: item6
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี เกี๊ยวกุ้งจักรพรรดิ",
                img: item7
            },
            {
                title: "ซีพี เกี๊ยวกุ้งรสต้มยำ",
                img: item8
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี เกี๊ยวหมูลุยสวน",
                img: item9
            },
            {
                title: "ซีพี เกี๊ยวซ่าหมู",
                img: item10
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี เกี๊ยวหมูซอสญี่ปุ่น",
                img: item11
            },

        ]
    },

]