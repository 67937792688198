import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import "./styles.css"

import success from "../../assets/images/logo/success.png"
// import success2 from "../../assets/images/logo/success-2.png"
import cp from "../../assets/images/logo/CP_Logo.png"
import failed from "../../assets/images/logo/failed.png"
const Popup = (
    {
        handleAcceptPopup,
        status,
        titles,
        subTitle,
        isShowPopup,
        buttonText
    }
) => {

    return (
        <div id="custom-sweet-alert">
            <SweetAlert
                style={{
                    overflow: 'none !important '
                }}
                customIcon={status === 'success' ? success : failed}
                custom
                closeOnClickOutside={false}
                showConfirm={true}
                showCancel={false}
                confirmBtnText={buttonText}
                showCloseButton={false}
                show={isShowPopup}
                confirmBtnBsStyle="btn btn-primary  text-white mt-3"
                title={titles}
                onCancel={() => handleAcceptPopup(false)}
                onConfirm={() => handleAcceptPopup(!isShowPopup)}
            >


                <div className="py-3 w-100 text-center text-popup">
                    {subTitle}
                    <br />
                    {
                        status === 'success' &&
                        <>
                            <span>
                                ติดตามรายชื่อผู้โชคดีได้ทางเว็บไซต์นี้ และทาง
                                Facebook Fanpage:
                                <img src={cp} alt="cp" style={{ width: '20px', display: 'inline' }} /> Brand ค่ะ
                            </span>
                            <span className="border-content-popup  text-center mt-3 ">
                                บริษัทฯ จะทำการติดต่อกลับผู้โชคดีทางเบอร์โทรศัพท์มือถือ เพื่อยืนยันตัวตนและรับรางวัลจากกิจกรรม
                                <br />
                                หากไม่สามารถติดต่อได้ภายใน 3 วันทำการ ทางบริษัทฯ จะถือว่าสละสิทธิ์ให้กับรายชื่อสำรองในลำดับถัดไป
                            </span>
                        </>
                    }
                </div>

            </SweetAlert>
        </div>
    )
}

export default Popup
