// export const config_cloud = {
//     apiKey: "AIzaSyA7MrXSfeVarhNaaLWEvHifYDYVdmTzQEQ",
//     authDomain: "fir-2c873.firebaseapp.com",
//     projectId: "fir-2c873",
//     storageBucket: "fir-2c873.appspot.com",
//     messagingSenderId: "110211619084",
//     appId: "1:110211619084:web:a19067b4abff36cee61129",
//     measurementId: "G-0XWKMZFNY8"
// }

export const config_cloud = {
    apiKey: "AIzaSyDm2Xh0KFzFV5wS-QAlKpBHNUCTsEWJkro",
    authDomain: "cpchinesenewyear-2022-landing.firebaseapp.com",
    projectId: "cpchinesenewyear-2022-landing",
    storageBucket: "cpchinesenewyear-2022-landing.appspot.com",
    messagingSenderId: "869054213834",
    appId: "1:869054213834:web:daa103c731bf7715884e33",
    measurementId: "G-W86KTL2R4F"
}