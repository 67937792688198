import MainLayout from "../../mainLayout/MainLayout"
import Underline from "../Light/light"
import Shop from "../Shop/Shop"
import { products, productsInMobileSize } from "./data"
import { productsdataBigpack, productsdataBigpackInMobileSize } from "./dataBigpack"
import { productsdataMinipack, productsdataMinipackInMobileSize } from "./dataMinipack"
import bgGold from "../../assets/images/landing/card-bg.png"
import productnName from "../../assets/images/landing/2023/product-name.png"
import kv2 from "../../assets/images/2023/kv2.png"
import fire from "../../assets/images/landing/2023/fire.png"

import footer_left from "../../assets/images/2024/Left.png"
import footer_right from "../../assets/images/2024/Right.png"
import cloud_left from "../../assets/images/2024/cloud-left.png"
import cloud_right from "../../assets/images/2024/cloud-right.png"
import Light from "../../assets/images/2024/light.png"
import "./styles.css"
const Home = () => {
    return (
        <MainLayout>
            <div className="container-fluid p-0 w-100">

                {/* <img width="100%" className="kv2 mt-0" src={ kv2 } /> */}
                {/*      
                <div className="section-title section-mg">
                    <div className="shadow-first-section"> */}
                {/* <h1 className="color-yellow">
                            สินค้าไหว้เจ้าซีพี แห่งปีเสือทอง 2565
                            <Underline />
                        </h1> */}
                {/* <h2 className="color-yellow text-center mg-t h-content mg-tt">
                        สะดวกไหว้ สะดวกเฮง ด้วยสินค้าไหว้เจ้าซีพี 
                        <Underline />
                        </h2>

                        <h3 className="text-white text-skip-br text-center px-3 text-content font-light">
                        ไหว้ด้วยสินค้าซีพีคุณภาพดีที่หลากหลาย ทั้งเป็ด ไก่ หมู กุ้ง คุ้มค่า อร่อย พร้อมไหว้ พร้อมทานหาซื้อง่าย สามารถจัดชุดไหว้ได้ทุกโอกาส และเทศกาล สะดวกไหว้แบบไหน เลือกไหว้ได้ตามใจคุณ
                        </h3> */}

                {/* <h3 className="text-white text-skip-br text-center px-3 text-content font-light">
                        พร้อมไหว้ พร้อมทานหาซื้อง่าย สามารถจัดชุดไหว้ได้ทุกโอกาส
                        </h3>
                        <h3 className="text-white text-skip-br text-center px-3 text-content section-end font-light">
                        และเทศกาล สะดวกไหว้แบบไหน เลือกไหว้ได้ตามใจคุณ
                        </h3> */}
                {/* </div>
                   
                </div> */}
                <div className="footer_flower footer_flower-top" style={{ paddingTop: '370px', position: 'absolute' }}>
                    <img className="footer_flower-left" src={footer_left} style={{ width: '30%' }} />
                    <img className="footer_flower-right" src={footer_right} style={{ width: '30%' }} />
                </div>
                <div className="container-fluid shopnow5 bg-card-text" style={{}} >
                    <div className="wrapper-shop-text container-fluid shopnow5">
                        <div className="">
                            <div className="fire-text">
                                <img src={fire} alt="สั่งซื้อซีพีของไหว้ ที่ Lotus's Online" className="fl-left" />
                                <img src={fire} alt="สั่งซื้อซีพีของไหว้ ที่ Lotus's Online" className="fl-right" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center section-title">
                            <div className="shadow-first-section-text" >
                                {/* <h4 className="text-gold title-desktop text-center text-head-shop">สะดวกไหว้ สะดวกเฮง ด้วยสินค้าไหว้เจ้าซีพี</h4> */}
                                <h4 className="text-gold title-mobile text-center text-head-shop">สะดวกไหว้ สะดวกเฮง ด้วยสินค้าไหว้เจ้าซีพี</h4>
                            </div>
                        </div>
                        <div className="bg-gold">
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }} className="underline-shadow-first-section-text">
                                <img className="light" src={Light} style={{ width: '30%' }} />
                            </div>
                            <div className="container d-flex h-100 justify-content-center flex-nowrap flex-xl-nowrap underline-shadow-first-section-texts">

                                <h3 className="text-skip-br text-center px-3 text-content font-light" style={{ color: '#000', paddingTop: '35px', lineHeight: '45px' }}>
                                    ไหว้ด้วยสินค้าซีพีคุณภาพดีที่หลากหลาย ทั้งเป็ด ไก่ หมู กุ้ง คุ้มค่า อร่อย พร้อมไหว้ พร้อมทานหาซื้อง่าย สามารถจัดชุดไหว้ได้ทุกโอกาส และเทศกาล สะดวกไหว้แบบไหน เลือกไหว้ได้ตามใจคุณ
                                </h3>

                            </div>
                        </div>
                    </div>
                    {/* <div className="footer_flower">
                        <img className="footer_flower-left" src={footer_left} />
                        <img className="footer_flower-right" src={footer_right} />
                    </div> */}
                </div>




                <h1 className="color-red text-center mg-t h-content" id="head_menu1" style={{ marginTop: '8rem', marginBottom: '2rem' }}>
                    ชุดไหว้ดั้งเดิม
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }} className="underline-shadow-first-section-text">
                        <img className="light" src={Light} style={{ width: '30%' }} />
                    </div>
                    <div className="" style={{ marginTop: ' -40px', position: 'absolute' }}>
                        <img className="footer_flower-left cloud-left" src={cloud_right} style={{ width: '10%', marginLeft: '30%', filter: 'drop-shadow(10px 5px 5px #8C4B32)' }} />
                        <img className="footer_flower-right cloud-right" src={cloud_left} style={{ width: '10%', marginRight: '30%', filter: ' drop-shadow(-10px 5px 5px #8C4B32)' }} />
                    </div>
                </h1>




                <div className="wrapper-product-list container p-0 my-5 product-pc">
                    {
                        productsdataBigpack.map((item, index) => {
                            return (
                                <div className="row d-flex justify-content-start pt-5 " key={index}>
                                    {
                                        item.group.map((product, idx) => {
                                            return (
                                                <div className="wrapper-product col-lg-4  text-center" key={idx}>
                                                    <img src={product.img} alt={product.title} className="w-100" />
                                                    <div className="product-name">
                                                        <h4 className="text-gold title-des">{product.title} <br />{product.title2}</h4>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="wrapper-product-list container product-mobile">
                    {
                        productsdataBigpackInMobileSize.map((item, index) => {
                            return (
                                <div className="row d-flex justify-content-start" key={index}>
                                    {
                                        item.group.map((product, idx) => {
                                            return (
                                                <div className="wrapper-product col-6 text-center" key={idx}>
                                                    <img src={product.img} alt={product.title} className="w-100" />
                                                    <div className="product-name" >
                                                        <h4 className="text-gold title-mobile ">{product.title} <br />{product.title2}</h4>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                            )
                        })
                    }
                </div>

                <br /><br />
                {/* <h1 className="color-yellow text-center mg-t h-content" id="head_menu2">
                    ชุดไหว้สะดวกมงคล
                    <Underline />
                </h1> */}

                <h1 className="color-red text-center mg-t h-content" id="head_menu2" style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                    ชุดไหว้สะดวกมงคล
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }} className="underline-shadow-first-section-text">
                        <img className="light" src={Light} style={{ width: '30%' }} />
                    </div>
                    <div className="" style={{ marginTop: ' -40px', position: 'absolute' }}>
                        <img className="footer_flower-left cloud-left" src={cloud_right} style={{ width: '10%', marginLeft: '30%', filter: 'drop-shadow(10px 5px 5px #8C4B32)' }} />
                        <img className="footer_flower-right cloud-right" src={cloud_left} style={{ width: '10%', marginRight: '30%', filter: ' drop-shadow(-10px 5px 5px #8C4B32)' }} />
                    </div>
                </h1>
                <div className="wrapper-product-list container product-pc" style={{ paddingBottom: '55px' }}>
                    {
                        productsdataMinipack.map((item, index) => {
                            return (
                                <div className="row d-flex justify-content-start " key={index}>
                                    {
                                        item.group.map((product, idx) => {
                                            return (
                                                <div className="wrapper-product col-lg-4  text-center" key={idx}>
                                                    <img src={product.img} alt={product.title} className="w-100" />
                                                    <div className="product-name" >
                                                        <h4 className="text-gold title-des">{product.title} <br />{product.title2}</h4>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ marginTop: "-30px" }} className="wrapper-product-list container product-mobile">
                    {
                        productsdataMinipackInMobileSize.map((item, index) => {
                            return (
                                <div className="row d-flex justify-content-start" key={index}>
                                    {
                                        item.group.map((product, idx) => {
                                            return (
                                                <div className="wrapper-product col-6 text-center" key={idx}>
                                                    <img src={product.img} alt={product.title} className="w-100" />
                                                    <div className="product-name" >
                                                        <h4 className="text-gold title-mobile">{product.title} <br />{product.title2}</h4>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                            )
                        })
                    }
                </div>
                <br /><br />
                <div className="wrapper-shopping container-fluid pt-4 bg-red" id="section-1">
                    <Shop />
                </div>
            </div>
        </MainLayout>
    )
}

export default Home
