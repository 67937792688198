import cpLogo from "../assets/images/landing/2023/CNY-Logo.png"
import cpKVproduct from "../assets/images/landing/CP-CNY2022_KV-Home-product.png"
// import cpKVcampaign from "../assets/images/landing/CP-CNY2022_KV-campaign.png"
import cpKVcampaign from "../assets/images/landing/AW_CP_Chinese_ประกาศรางวัล_Website-1.jpg"


import cpKVhome from "../assets/images/landing/CP-CNY2022_KV-Home.png"
import foreground from "../assets/images/landing/CP-CNY2022_KV-frame.png"
import backgroundImage from "../assets/images/landing/CP-CNY2022_KV-bg.png"
import base from "../assets/images/landing/CP-CNY2022_KV-based.png"

import { Link } from "react-router-dom"

import baseItem from "../assets/images/landing/item-1.png"
import tradition from "../assets/images/landing/tradition.png"


import cpfLogo from "../assets/images/landing/CPF_Logo 1.png"
import cpfLogo_home from "../assets/images/2024/logo_2024.png"
import cpfLogo_home2 from "../assets/images/2023/Logo_home2.png"
import email from "../assets/images/landing/email.png"
import tel from "../assets/images/landing/tel.png"
import Icon_Facebook from "../assets/images/landing/facebook.png"
import Icon_Instagram from "../assets/images/landing/instagram.png"
import Icon_Youtube from "../assets/images/landing/youtube.png"
import Underline from "../components/Light/light"
import Sidebar from "../components/Sidebar/Sidebar"
import bgHome from "../assets/images/landing/2023/footban.png"
import bgMu from "../assets/images/landing/2023/bgMu.png"
import toShop from "../assets/images/landing/2023/to-shop.png"
import hovertoShop from "../assets/images/landing/2023/Hover-to-shop.png"


import buttonmenu1 from "../assets/images/2023/buttonmenu3-1.png"
import Hoverbuttonmenu1 from "../assets/images/2023/Hoverbuttonmenu2-1.png"

import buttonmenu2 from "../assets/images/2023/buttonmenu3-2.png"
import Hoverbuttonmenu2 from "../assets/images/2023/Hoverbuttonmenu2-2.png"

import buttonmenu3 from "../assets/images/2023/buttonmenu3-3.png"
import Hoverbuttonmenu3 from "../assets/images/2023/Hoverbuttonmenu2-3.png"

// import footer_left from "../assets/images/2023/footer_left.png"
// import footer_right from "../assets/images/2023/footer_right.png"
import footer_left from "../assets/images/2024/dragon2.webp"
import footer_right from "../assets/images/2024/dragon1.webp"
import footer_line1 from "../assets/images/2023/footer_line1.png"
import footer_line2 from "../assets/images/2023/footer_line2.png"
import "./styles.css"
import { useEffect, useState } from "react"

import Carousel from 'react-bootstrap/Carousel';
import slider1 from '../assets/images/2024/KV1.webp'
import slider2 from '../assets/images/2024/KV2.webp'

const MainLayout = (props) => {

    const [aceeptTerm, setAccepterm] = useState(false)
    const { children } = props
    const pathName = window.location.pathname

    useEffect(() => {
        if (localStorage.getItem('accept') != null) {
            setAccepterm(true)
        }
    }, [])

    const acceptTern = (isAccept) => {
        setAccepterm(isAccept)
        localStorage.setItem('accept', 'accept')
    }

    const handleClickScroll = () => {
        const element = document.getElementById('section-1');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickScroll_menu1 = () => {
        const element = document.getElementById('head_menu1');
        if (element) {
            // window.location.href = '/home#head_menu1';
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            setTimeout(() => {
                const element2 = document.getElementById('head_menu1');
                if (element2) {
                    // window.location.href = '/home#head_menu2';
                    // 👇 Will scroll smoothly to the top of the next section
                    element2.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100)
        }
    }

    const handleClickScroll_menu2 = () => {

        const element = document.getElementById('head_menu2');
        if (element) {
            // window.location.href = '/home#head_menu2';
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            setTimeout(() => {
                const element2 = document.getElementById('head_menu2');
                if (element2) {
                    // window.location.href = '/home#head_menu2';
                    // 👇 Will scroll smoothly to the top of the next section
                    element2.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100)
        }


    }


    return (
        <>

            <Carousel className={pathName === '/home' || pathName === '/' ? "carousel-slide slide-pc" : "carousel-slide d-none"}  >
                <Carousel.Item>
                    <img className="d-block w-100" src={slider1} alt="First slide" />
                    <Carousel.Caption>
                        <Link to="/home">
                            <img src={pathName === '/home' || pathName === '/' ? cpfLogo_home : cpfLogo_home} alt="ซีพี ของไหว้ ปีเสือทอง 2565" className={pathName === '/home' || pathName === '/' ? "logo" : "logo"} />
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <Carousel className={pathName === '/home' || pathName === '/' ? "carousel-slide slide-mobile product-mobile" : "carousel-slide d-none"}  >
                <Carousel.Item>
                    <img className="d-block w-100" src={slider1} alt="First slide" />
                    <Carousel.Caption>
                        <Link to="/home">
                            <img src={pathName === '/home' || pathName === '/' ? cpfLogo_home : cpfLogo_home} alt="ซีพี ของไหว้ ปีเสือทอง 2565" className={pathName === '/home' || pathName === '/' ? "logo" : "logo"} />
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            {/* slider2 */}

            <Carousel className={pathName != '/home' && pathName != '/' ? "carousel-slide slide-pc" : "carousel-slide d-none"}  >
                <Carousel.Item>
                    <img className="d-block w-100" src={slider2} alt="First slide" />
                    <Carousel.Caption>
                        <Link to="/home">
                            <img src={pathName != '/home' && pathName != '/' ? cpfLogo_home : cpfLogo_home} alt="ซีพี ของไหว้ ปีเสือทอง 2565" className={pathName != '/home' && pathName != '/' ? "logo" : "logo"} />
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <Carousel className={pathName != '/home' && pathName != '/' ? "carousel-slide slide-mobile product-mobile" : "carousel-slide d-none"}  >
                <Carousel.Item>
                    <img className="d-block w-100" src={slider2} alt="First slide" />
                    <Carousel.Caption>
                        <Link to="/home">
                            <img src={pathName != '/home' && pathName != '/' ? cpfLogo_home : cpfLogo_home} alt="ซีพี ของไหว้ ปีเสือทอง 2565" className={pathName != '/home' && pathName != '/' ? "logo" : "logo"} />
                        </Link>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <div className="container-fluid">
                <div className="sidebar">
                    <Sidebar />
                    <span class="menu-sidebar">เมนู</span>
                </div>
                <div className="row">
                    <div className={pathName === '/home' || pathName === '/' ? "wrapper-banner bg-fullbg cover-original-has-light" : "wrapper-banner bg-fullbg cover-original-has-light"}>
                        <nav className={pathName === '/home' || pathName === '/' ? "nav_list nav_list_home mx-auto text-center" : "nav_list nav_list_home mx-auto text-center"}>
                            <Link className={" btn btn-menu bg-menu"} to="/home" onClick={handleClickScroll_menu1} >
                                <img width="90%" src={buttonmenu1} className={"button-1"} /><img width="100%" src={Hoverbuttonmenu1} className={"hoverbutton-1"} /></Link>

                            <Link className={" btn btn-menu bg-menu"} to="/home" onClick={handleClickScroll_menu2} >
                                <img width="100%" src={buttonmenu2} className={"button-2"} /><img width="110%" src={Hoverbuttonmenu2} />

                            </Link>
                            <Link className={" btn btn-menu bg-menu"} to="/tradition"><img width="90%" src={buttonmenu3} className={"button-3"} /><img width="100%" src={Hoverbuttonmenu3} /></Link>
                        </nav>
                        <div className="banner-foreground">
                        </div>

                    </div>
                    {pathName === '/home' || pathName === '/' ?
                        <div className="shop-center">
                            <a href="#section-1" onClick={handleClickScroll} className='shopDesktop'> <img width="100%" href="section-1" src={toShop} className={'toshop'} /> </a>
                            <a href="#section-1" onClick={handleClickScroll}> <img href="section-1" width="100%" src={hovertoShop} className={'toshop-hover'} /></a>
                        </div>
                        :
                        <div className="shop-center">
                            <a href="#section-1" onClick={handleClickScroll} className='shopDesktop'> <img width="100%" href="section-1" src={toShop} className={'toshop'} /> </a>
                            <a href="#section-1" onClick={handleClickScroll}> <img href="section-1" width="100%" src={hovertoShop} className={'toshop-hover'} /></a>
                        </div>
                    }
                    <div className={pathName != '/home' && pathName != '/' ? "wrapper-body col-12 p-0 bg-red" : "wrapper-body col-12 p-0 "}>
                        {children}
                    </div>
                    <div className="footer_flower bg-red" >
                        <div className="footer_line">
                            <img className="footer_line-left" src={footer_line1} />
                            <img className="footer_line-center" src={footer_line1} />
                            <img className="footer_line-right" src={footer_line2} />
                        </div>
                        <img className="footer_flower-left" src={footer_left} style={{ width: '25%', zIndex: '100', position: 'relative' }} />
                        <img className="footer_flower-right" src={footer_right} style={{ width: '25%', zIndex: '100', position: 'relative' }} />
                    </div>
                    <div className="wrapper-footer col-12 footer py-4">
                        <div className="container d-flex flex-column justify-content-around align-items-center flex-wrap h-100 w-100">
                            <div className="d-flex flex-row w-100 justify-content-between align-items-center flex-wrap py-3">
                                <div className="wrapper-footer-contact">
                                    <div className="cpf-logo d-block">
                                        <img src={cpfLogo} alt="ศูนย์ผู้บริโภค ซีพีเอฟ" className="w-100px" />
                                    </div>
                                    <div className="cpf-contact d-flex flex-column justify-content-between ">
                                        <h5 className="font-medium">
                                            ศูนย์ผู้บริโภค ซีพีเอฟ
                                        </h5>
                                        <p>
                                            <img src={tel} alt="เบอรโทร ศูนย์ผู้บริโภค ซีพีเอฟ" />
                                            <a className="text-dark mx-1 font-medium" href="tel:028008000">+66 (0) 2800 8000</a>
                                        </p>
                                        <p>
                                            <img src={email} alt="อีเมล ศูนย์ผู้บริโภค ซีพีเอฟ" />
                                            <a className="text-dark mx-2 font-medium" href={"mailto:consumercenter@cpf.co.th"}>consumercenter@cpf.co.th</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="wrapper-footer-meta">
                                    <div className="d-flex flex-column justify-content-between w-100">
                                        <h5 className="text-center font-medium">
                                            ติดตามความอร่อยเพิ่มเติม
                                        </h5>
                                        <div className="d-flex mt-3 justify-content-between flex-wrap">
                                            <a className="text-dark" target="_blank" href="https://www.facebook.com/brandcp/">
                                                <img className="w-60px" src={Icon_Facebook} alt="Facebook @ CP Brand" />
                                            </a>
                                            <a className="text-dark mx-4" target="_blank" href="https://www.instagram.com/cpfood/">
                                                <img className="w-60px" src={Icon_Instagram} alt="Instagram @ CP Brand" />
                                            </a>
                                            <a className="text-dark" target="_blank" href="https://www.youtube.com/user/cpbrandthailand/">
                                                <img className="w-60px" src={Icon_Youtube} alt="YouTube @ CP Brand" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex footer-detail w-100">
                                <div className="d-flex justify-content-between w-100 flex-wrap">
                                    <div>
                                        <a className="pe-2 font-medium" target="_blank" href="https://www.cpbrandsite.com/privacy">นโยบายความเป็นส่วนตัว</a>
                                        |
                                        <a className="px-2 font-medium" target="_blank" href="https://www.cpbrandsite.com/terms">ข้อกำหนดและเงื่อนไข</a>
                                    </div>
                                    <div className="font-medium">
                                        © 2565 บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน)
                                    </div>
                                </div>
                                {
                                    aceeptTerm == false &&
                                    <div id="cookiesbar">
                                        <div className="font-medium">
                                            cpbrandsite.com ใช้คุกกี้บนเว็บไซต์นี้เพื่อการบริหารเว็บไซต์ และเพิ่มประสิทธิภาพการใช้งานของท่าน
                                            <a href="https://www.cpbrandsite.com/cookies" className="mx-3" target={'_blank'}>
                                                เรียนรู้เพิ่มเติม
                                            </a>
                                            <button className="btn btn-accept ml-3" onClick={() => acceptTern(!aceeptTerm)}>
                                                ยอมรับ
                                            </button>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </>

    )
}

export default MainLayout
