import MainLayout from "../../mainLayout/MainLayout"
import Underline from "../Light/light"
import Shop from "../Shop/Shop"
import mu from "../../assets/images/landing/2023/date2.png"
import pay from "../../assets/images/landing/2023/date1.png"
import newYear from "../../assets/images/landing/2023/date3.png"
import planMu from "../../assets/images/2023/MuGroups.png"
import planMuMobile from "../../assets/images/2023/MuGroupsMobile.png"
import mongkhol from "../../assets/images/2023/mongkhol-s-new.png"
import video from "../../assets/images/landing/2023/video.png"

// import item1 from "../../assets/images/landing/2023/product/1.png"
// import item2 from "../../assets/images/landing/2023/product/2.png"
// import item3 from "../../assets/images/landing/2023/product/3.png"
// import item4 from "../../assets/images/landing/2023/product/4.png"
// import item5 from "../../assets/images/2023/5.png"

import item1 from "../../assets/images/2023/pd/1.png"
import item2 from "../../assets/images/2023/pd/2.png"
import item3 from "../../assets/images/2023/pd/3.png"
import item4 from "../../assets/images/2023/pd/4.png"
import item5 from "../../assets/images/2023/pd/5.png"
import { useEffect } from "react"


// import item6 from "../../assets/images/landing/2023/product/6.png"
// import item7 from "../../assets/images/landing/2023/product/7.png"
// import item8 from "../../assets/images/landing/2023/product/8.png"


const Tradition = () => {

    useEffect(() => {
        let element = document.getElementById('root');
        element.scrollIntoView({ behavior: 'smooth' });
    }, [])

    return (
        <MainLayout >
            <div id="head_tradition" className="container-fluid   p-0 w-100">
                {/* <div className="container-fluid p-0 w-100"> */}
                <div className="first-tradition">
                    {/* <div className="shadow-first-section"> */}
                    {/* <h1 className="color-yellow">
                            ธรรมเนียมการไหว้ตรุษจีน
                            <Underline />
                        </h1> */}
                    {/* </div> */}

                    <h1 className="color-yellow text-center head-1">
                        ไหว้เจ้าเทศกาลตรุษจีน
                        <Underline />
                    </h1>
                    <h3 className="text-white text-center  title-1 font-light product-pc">
                        ตรุษจีน หรือที่ชาวจีนเรียกว่าเทศกาลฤดูใบไม้ผลิ หรือ ขึ้นปีเพาะปลูกใหม่ และยังรู้จักกัน<br />
                        ในนามวันขึ้นปีใหม่ทางจันทรคติ เป็นวันขึ้นปีใหม่ตามประเพณีของชาวจีน
                    </h3>
                    <h3 className="text-white text-center  title-1 font-light product-mobile">
                        ตรุษจีน หรือที่ชาวจีนเรียกว่าเทศกาลฤดูใบไม้ผลิ หรือ ขึ้นปีเพาะปลูกใหม่ และยังรู้จักกัน
                        ในนามวันขึ้นปีใหม่ทางจันทรคติ เป็นวันขึ้นปีใหม่ตามประเพณีของชาวจีน
                    </h3>
                    <h1 className="text-white text-center  title-2">
                        ชาวจีน จะถือประเพณีปฏิบัติอยู่ 3 วัน คือวันจ่าย วันไหว้และวันขึ้นปีใหม่
                    </h1>
                </div>

                <div className="wrapper-box-3 container container-hwai">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12 content-center">
                            <div className="tradition-img" >
                                <img src={pay} alt="" className="img-s3" />
                            </div>
                            <div className="tradition-name" >
                                <h4 className="text-gold title-mobile text-center">วันจ่าย</h4>
                            </div>

                            <h5 className="text-white line-height-text pt-5 tradition-detail font-light ">
                                ตื่อเส็ก (วันก่อนวันสิ้นปี) คือ วันที่ต้องซื้อของไหว้ต่างๆ เลือกของไหว้มงคลจากซีพี สะดวก หาซื้อได้ทั้งออนไลน์และห้างใกล้บ้าน ได้ของครบ ถูกหลักมงคล ช่วงค่ำมีการอัญเชิญถี่จู่เอี๊ยให้มารับ การสักการะบูชาของเจ้าบ้าน
                            </h5>

                        </div>
                        <div className="col-lg-4 col-md-6 col-12 content-center">
                            <div className="tradition-img" >
                                <img src={mu} alt="" className="img-s3" />
                            </div>
                            <div className="tradition-name" >
                                <h4 className="text-gold title-mobile text-center">วันไหว้</h4>
                            </div>
                            <h5 className="text-white line-height-text pt-5 tradition-detail font-light product-pc">
                                คือ วันสิ้นปีจะมีการไหว้ 3 ครั้ง <br /><br /> 1) ตอนเช้ามืด ไหว้เทพเจ้า<br />เปิดดวงด้วย รับทรัพย์ด้วย <b>ชุดไหว้เสริมทรัพย์</b> <br /> คัดความสวย คุณภาพดี (ซีพีขาหมูพะโล้, เป็ดพะโล้ต้นตำรับ,ไก่ต้ม) หรือไหว้ด้วย <b>ชุดเรียกทรัพย์</b> <br />สะดวกไหว้ ถูกหลักมงคล (ซีพีนักเก็ตไก่, เป็ดพะโล้, เกี๊ยวกุ้งจักรพรรดิ)<br /><br />2) ตอนสาย (ก่อนเที่ยง) ไหว้บรรพบุรุษ ให้ชีวิตรุ่งเรือง ด้วย<b>ชุดไหว้เสริมบารมี</b> คัดความสวย คุณภาพดี (ซีพีเป็ดพะโล้ต้นตำรับ, ไก่ต้ม, ขาหมูและ คากิพะโล้, ซาลาเปามงคล, เกี๊ยวกุ้งจักรพรรดิ) หรือไหว้ด้วย  <b>ชุดเมตตามงลง</b> สะดวกไหว้ถูกหลักมงคล (ซีพีเป็ดพะโล้, ขาหมูพะโล้,เป็ดย่าง, นักเก็ตไก่, เกี๊ยวกุ้งจักรพรรดิ)<br /><br />3) ตอนบ่าย จะไหว้ไป๊ฮ้อเฮียตี๋เสริมสิริมงคลด้วยขนมเข่ง ขนมเทียน และจุดประทัด เพื่อขับไล่อัปมงคล
                            </h5>
                            <h5 className="text-white line-height-text pt-5 tradition-detail font-light product-mobile">
                                คือ วันสิ้นปีจะมีการไหว้ 3 ครั้ง <br /><br /> 1) ตอนเช้ามืด ไหว้เทพเจ้า<br />เปิดดวงด้วย รับทรัพย์ด้วย <br /><b>ชุดไหว้เสริมทรัพย์</b> คัดความสวย คุณภาพดี (ซีพีขาหมูพะโล้, เป็ดพะโล้ต้นตำรับ,ไก่ต้ม) หรือไหว้ด้วย <br /> <b>ชุดเรียกทรัพย์</b> สะดวกไหว้ ถูกหลักมงคล (ซีพีนักเก็ตไก่, เป็ดพะโล้, เกี๊ยวกุ้งจักรพรรดิ)<br /><br />2) ตอนสาย (ก่อนเที่ยง) ไหว้บรรพบุรุษ ให้ชีวิตรุ่งเรือง ด้วย <br /><b>ชุดไหว้เสริมบารมี</b> คัดความสวย คุณภาพดี (ซีพีเป็ดพะโล้ต้นตำรับ, ไก่ต้ม, ขาหมูและ คากิพะโล้, ซาลาเปามงคล, เกี๊ยวกุ้งจักรพรรดิ) หรือไหว้ด้วย  <br /><b>ชุดเมตตามงลง</b> สะดวกไหว้ถูกหลักมงคล (ซีพีเป็ดพะโล้, ขาหมูพะโล้,เป็ดย่าง, นักเก็ตไก่, เกี๊ยวกุ้งจักรพรรดิ)<br /><br />3) ตอนบ่าย จะไหว้ไป๊ฮ้อเฮียตี๋เสริมสิริมงคลด้วยขนมเข่ง ขนมเทียน และจุดประทัด เพื่อขับไล่อัปมงคล
                            </h5>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12 content-center">
                            <div className="tradition-img" >
                                <img src={newYear} alt="" className="img-s3" />
                            </div>
                            <div className="tradition-name" >
                                <h4 className="text-gold title-mobile text-center">วันขึ้นปีใหม่</h4>
                            </div>
                            <h5 className="text-white line-height-text pt-5 tradition-detail font-light">
                                หรือวันเที่ยว คือ วันที่หนึ่งของเดือน ที่หนึ่งของปี (ชิวอิก) ชาวจีนจะไหว้ขอพร จากญาติผู้ใหญ่และผู้ที่เคารพรัก ในวันนี้เป็นวันที่ผู้ใหญ่จะให้อั่งเปา หรือ แต๊ะเอีย (ซองแดงที่ใส่เงิน) ให้ผู้น้อย
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="container mt-5 mt-sm-2">
                    <div className="section-title section-mu">
                        <h1 className="color-yellow head-1 desktop-h1">
                            ไหว้เจ้าถูกหลักเสริมมงคล ตามแบบฉบับดั้งเดิม
                            <Underline />
                        </h1>
                        <h1 className="color-yellow text-center mobile-h1 head-1">
                            ไหว้เจ้าถูกหลักเสริมมงคล<br />ตามแบบฉบับดั้งเดิม
                            <Underline />
                        </h1>
                        <h5 className="pt-md-5 pt-sm-2 text-center text-white title-1 font-light product-pc">
                            การจัดโต๊ะไหว้ให้ถูกหลักธรรมเนียม จะช่วยเสริมมงคล และดึงดูดสิ่งดีๆ สู่ครอบครัวโดยจัดดังนี้ <br />ตั้งโต๊ะไหว้ไว้หน้าแท่นบูชา บนสุดของโต๊ะวางกระถางธูปและตั้งเชิงเทียนขนาบซ้าย/ขวา<br /> วางกระดาษเงิน กระดาษทองรองลงมา แถวถัดไปวางถ้วยน้ำชา หรือถ้วยเหล้า จากนั้นวางของคาว<br />ด้วยของไหว้ ”ซาแซ/โหวงแซ” ที่คัดความสวย คุณภาพดี ถูกหลักมงคลตามความเชื่อ<br />และท้ายสุดตามด้วยของหวานและผลไม้
                        </h5>

                        <h5 className="pt-md-5 pt-sm-2 text-center text-white title-1 font-light product-mobile">
                            การจัดโต๊ะไหว้ให้ถูกหลักธรรมเนียม จะช่วยเสริมมงคล และดึงดูดสิ่งดีๆ สู่ครอบครัวโดยจัดดังนี้ ตั้งโต๊ะไหว้ไว้หน้าแท่นบูชา บนสุดของโต๊ะวางกระถางธูปและตั้งเชิงเทียนขนาบซ้าย/ขวา  วางกระดาษเงิน กระดาษทองรองลงมา แถวถัดไปวางถ้วยน้ำชา หรือถ้วยเหล้า จากนั้นวางของคาว ด้วยของไหว้ ”ซาแซ/โหวงแซ” ที่คัดความสวย คุณภาพดี ถูกหลักมงคลตามความเชื่อ และท้ายสุดตามด้วยของหวานและผลไม้
                        </h5>

                        <div className="col-12 pt-5">
                            <img src={planMu} className="w-100 product-pc" alt="" />
                            <img src={planMuMobile} className="w-100 product-mobile" alt="" />
                        </div>
                        <h3 className="text-white text-center pt-5 title-1 product-pc">
                            เสริมความเฮงให้ครบ ถูกต้องตามลักษณะความเชื่อ ด้วยของไหว้คุณภาพดี<br />ตัวสวย ชิ้นใหญ่ เนื้อแน่นเต่งตึง เตรียมรับสิ่งดีๆ เฮงๆ เข้ามาในชีวิต ด้วยของไหว้ซีพี
                        </h3>
                        <h3 className="text-white text-center pt-5 title-1 product-mobile">
                            เสริมความเฮงให้ครบ ถูกต้องตามลักษณะความเชื่อ ด้วยของไหว้คุณภาพดี ตัวสวย ชิ้นใหญ่ เนื้อแน่นเต่งตึง เตรียมรับสิ่งดีๆ เฮงๆ เข้ามาในชีวิต ด้วยของไหว้ซีพี
                        </h3>
                        <h3 className=" text-white text-left pt-md-5 pt-sm-2 planMu title-1 font-light">
                            <table className="tabel-Mu ">
                                <tr>
                                    <td className="text-right"> <font className="text-gold">หมู</font></td>
                                    <td className="pt-right-10">หมายถึง ความมั่งคั่ง โดยความอ้วนของตัวหมู สะท้อนถึงความกินดีอยู่ดีในครอบครัว</td>
                                </tr>

                                <tr>
                                    <td className="text-right"> <font className="text-gold">ไก่</font></td>
                                    <td className="pt-right-10">มีความหมายมงคล 2 อย่าง คือ</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td className="pt-right-10">หงอนไก่ สื่อถึงหมวกขุนนาง ความหมายมงคลจึงเป็นความก้าวหน้าในการงาน</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td className="pt-right-10">ไก่ขันตรงเวลาทุกเช้า สะท้อนถึงการรู้งาน</td>
                                </tr>
                                <tr>
                                    <td className="text-right"> <font className="text-gold text-right">เป็ด</font></td>
                                    <td className="pt-right-10">หมายถึง สื่อถึงความบริสุทธิ์ สะอาด ความเก่งรอบด้าน และความสามารถอันหลากหลาย</td>
                                </tr>
                                <tr>
                                    <td className="text-right"> <font className="text-gold text-right">กุ้ง</font></td>
                                    <td className="pt-right-10">สื่อถึงชีวิตที่รุ่งเรืองและมีความสุข</td>
                                </tr>
                                <tr>
                                    <td className="text-right"> <font className="text-gold text-right">ตับ</font></td>
                                    <td className="pt-right-10">คำจีนเรียกว่า กัว พ้องเสียงกับคำว่า กัว ที่แปลว่าขุนทาง สื่อถึงยศตำแหน่ง</td>
                                </tr>
                                <tr>
                                    <td className="text-right"> <font className="text-gold text-right">ปลา</font></td>
                                    <td className="pt-right-10">คนจีนแต้จิ๋วเรียกว่า ฮื้อ โดยมีวลีมงคล อู่-ฮื้อ-อู่ แปลว่า ให้เหลือกินเหลือใช้</td>
                                </tr>
                            </table>
                            {/* <font className="text-gold">หมู</font> หมายถึง ความมั่งคั่ง โดยความอ้วนของตัวหมู สะท้อนถึงความกินดีอยู่ดีในครอบครัว<br />
                        <font className="text-gold">ไก่</font> มีความหมายมงคล 2 อย่าง คือ <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;หงอนไก่ สื่อถึงหมวกขุนนาง ความหมายมงคลจึงเป็นความก้าวหน้าในการงาน<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ไก่ขันตรงเวลาทุกเช้า สะท้อนถึงการรู้งาน<br /><font className="text-gold">เป็ด</font> หมายถึง สื่อถึงความบริสุทธิ์ สะอาด ความเก่งรอบด้าน และความสามารถอันหลากหลาย<br /><font className="text-gold">กุ้ง</font> สื่อถึงชีวิตที่รุ่งเรืองและมีความสุข<br /><font className="text-gold">ตับ</font> คำจีนเรียกว่า กัว พ้องเสียงกับคำว่า กัว ที่แปลว่าขุนทาง สื่อถึงยศตำแหน่ง <br /><font className="text-gold">ปลา</font> คนจีนแต้จิ๋วเรียกว่า ฮื้อ โดยมีวลีมงคล อู่-ฮื้อ-อู่ แปลว่า ให้เหลือกินเหลือใช้ */}

                        </h3>

                    </div>
                </div>
                <div className="container mt-5 container-space">
                    <div className="section-title">
                        <h1 className="color-yellow head-1">
                            ไหว้เจ้าให้ปัง เสริมพลังมงคล<br />ตอบโจทย์ทุกไลฟ์สไตล์ ของไหว้จากซีพี
                        </h1>
                        <h3 className="text-white text-center pt-5 title-1">
                            ของครบถูกหลักการไหว้ ด้วยเมนูอร่อย สะดวกไหว้ พร้อมทาน เสริมความเฮงครบทุกด้าน
                            <Underline />
                        </h3>
                        <div className="col-12 pt-5 tradition-table">
                            <img src={mongkhol} className="w-100" alt="" />
                        </div>
                        <h3 className="text-white text-center pt-5 title-1">
                            เลือกของไหว้สะดวกมงคลถูกหลักจากซีพี เสริมดวงให้มงลงถูกใจ
                        </h3>
                    </div>
                    <div className="mongkhol">
                        <div className="col-3 pt-5 pic-mongkhol">
                            <img src={item1} className="w-80" alt="" />
                        </div>
                        <div className="col-9 pt-5 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">เป็ดพะโล้</font> พลังเมตตา<br />

                                ของไหว้ยืน 1 ครอบคลุมทุกด้าน ด้วยเป็ดที่มีความสามารถหลากหลาย ทำอะไรก็พบเจอแต่ความสำเร็จ แถมยังช่วยเสริมเมตตา มีแต่คนอยากช่วยเหลือเกื้อกูล

                            </h3>
                        </div>
                    </div>
                    <div className="mongkhol">
                        <div className="col-3 pt-5 pic-mongkhol">
                            <img src={item2} className="w-80" alt="" />
                        </div>
                        <div className="col-9 pt-5 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">ขาหมู</font> รากฐานแห่งความมั่นคง<br />ขาหมู ช่วยเสริมดวงเรื่องการงาน เป็นขาใหญ่ในการทำงาน มีอำนาจ บารมี คนเคารพ ยำเกรง ให้ความเกื้อหนุน ประสบความสำเร็จ ทำให้หน้าที่การงานมั่นคง
                            </h3>
                        </div>
                    </div>
                    <div className="mongkhol">
                        <div className="col-3 pt-5 pic-mongkhol">
                            <img src={item3} className="w-80" alt="" />
                        </div>
                        <div className="col-9 pt-5 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">เป็ดย่าง</font> ย่างก้าวสู่ความสำเร็จ<br />เสริมดวงความก้าวหน้าด้วย “เป็ดย่าง” ช่วยเสริมให้ย่างก้าวไปสู่ความสำเร็จ ในหน้าที่การงาน ทำอะไรก็เจริญรุ่งเรือง ได้เพิ่มยศ เลื่อนตำแหน่ง
                            </h3>
                        </div>
                    </div>
                    <div className="mongkhol">
                        <div className="col-3 pt-5 pic-mongkhol">
                            <img src={item4} className="w-80" alt="" />
                        </div>
                        <div className="col-9 pt-5 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">นักเก็ตไก่</font> ทองแท่ง<br />เสริมมงคลเรื่องเงินทอง ต้องไหว้ด้วย “นักเก็ตไก่” ที่สื่อถึง ทองแท่ง ความมั่งคั่ง ร่ำรวย ช่วยเสริมให้มีเงินทองเหลือกิน เหลือใช้ ไม่ขัดสนตลอดทั้งปี
                            </h3>
                        </div>
                    </div>
                    <div className="mongkhol">
                        <div className="col-3 pt-5 pic-mongkhol">
                            <img src={item5} className="w-80" alt="" />
                        </div>
                        <div className="col-9 pt-5 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">เกี๊ยวกุ้ง</font> ก้อนทอง<br />เสริมดวงให้เฮง ได้โชคลาภก้อนใหญ่ ต้องไหว้ด้วย “เกี๊ยวกุ้ง” ที่สื่อ ก้อนทอง หรือกิมตุ้ง ช่วยดึงดูดเรื่องโชคลาภ ให้ได้รับเงินก้อนโต เปิดประตูดวงให้มีแต่ ความเฮง โชคดีตลอดปี
                            </h3>
                        </div>
                    </div>


                    <div className="mongkhol-m mongkhol-m-head">
                        <div className="col-3 pic-mongkhol">
                            <img src={item1} className="w-100" alt="" />
                        </div>
                        <div className="col-9 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">เป็ดพะโล้</font> พลังเมตตาของไหว้ยืน 1 เรื่องเสริมเสน่ห์    เมตตาอยากช่วยเหลือ ไปไหนก็พบเจอแต่มิตรภาพที่จริงใจ เก่งกาจรอบด้านความสามารถอันหลากหลาย ทำอะไรก็ประสบความสำเร็จ
                            </h3>
                        </div>
                    </div>
                    <div className="mongkhol-m">
                        <div className="col-3 pic-mongkhol">
                            <img src={item2} className="w-100" alt="" />
                        </div>
                        <div className="col-9 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">เป็ดย่าง</font> ย่างก้าวสู่ความสำเร็จ<br />เสริมดวงความก้าวหน้าด้วย “เป็ดย่าง” ช่วยเสริมให้ย่างก้าวไปสู่ความสำเร็จ<br />ในหน้าที่การงาน ทำอะไรก็เจริญรุ่งเรือง ได้เพิ่มยศ เลื่อนตำแหน่ง
                            </h3>
                        </div>
                    </div>
                    <div className="mongkhol-m">
                        <div className="col-3 pic-mongkhol">
                            <img src={item4} className="w-100" alt="" />
                        </div>
                        <div className="col-9 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">นักเก็ตไก่</font> ทองแท่งเสริมมงคลเรื่องเงินทอง ต้องไหว้ด้วย “นักเก็ตไก่” ที่สื่อถึง ทองแท่งความมั่งคั่ง ร่ำรวย ช่วยเสริมให้มีเงินทองเหลือกิน เหลือใช้ ไม่ขัดสนตลอดทั้งปี
                            </h3>
                        </div>
                    </div>
                    <div className="mongkhol-m">
                        <div className="col-3 pic-mongkhol">
                            <img src={item5} className="w-100" alt="" />
                        </div>
                        <div className="col-9 div-mongkhol-detail">
                            <h3 className="text-white text-left mongkhol-detail font-light">
                                <font className="text-gold">เกี๊ยวกุ้ง</font> ก้อนทองเสริมดวงให้เฮง ได้โชคลาภก้อนใหญ่ ต้องไหว้ด้วย “เกี๊ยวกุ้ง” ที่สื่อ ก้อนทองหรือกิมตุ้ง ช่วยดึงดูดเรื่องโชคลาภ ให้ได้รับเงินก้อนโต เปิดประตูดวงให้มีแต่ความเฮง โชคดีตลอดปี
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="wrapper-shopping container-fluid pt-4 bg-red">
                    <Shop />
                </div>
            </div>
        </MainLayout>
    )
}

export default Tradition
