import itembig1 from "../../assets/images/2024/product/product1.webp"
import itembig2 from "../../assets/images/2024/product/product2.webp"
import itembig3 from "../../assets/images/2024/product/product3.webp"
import itembig4 from "../../assets/images/2024/product/product4.webp"
import itembig5 from "../../assets/images/2024/product/product5.webp"
import itembig6 from "../../assets/images/2024/product/product6.webp"
import itembig7 from "../../assets/images/2024/product/product7.webp"
import itembig8 from "../../assets/images/2024/product/product8.webp"
import itembig9 from "../../assets/images/2024/product/product9.webp"
import itembig10 from "../../assets/images/2024/product/product10.webp"
import itembig11 from "../../assets/images/2024/product/product11.webp"
import itembig12 from "../../assets/images/2024/product/product12.webp"
import itembig13 from "../../assets/images/2024/product/product13.webp"
import itembig14 from "../../assets/images/2024/product/product14.webp"
export const productsdataBigpack = [
    {
        group: [

            {
                title: "ซีพี เป็ดพะโล้ต้นตำรับ",
                img: itembig1
            },
            {
                title: "ซีพี เป็ดย่างไหว้เจ้า",
                img: itembig2
            },
            {
                title: "ซีพี ขาหมูพะโล้",
                img: itembig3
            },
        ]
    },
    {
        group: [

            {
                title: "ซีพี ไก่ต้ม",
                img: itembig4
            },
            {
                title: "ซีพี ไก่ต้มน้ำปลา",
                img: itembig5
            },
            {
                title: "ซีพี ขาหมูและคากิพะโล้",
                img: itembig6
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี ขาหมูเยอรมัน หมูดำ",
                title2: "ซีพี-คูโรบูตะ",
                img: itembig7
            },
            {
                title: "ซีพี หมูกรอบชาชู หมูดำ",
                title2: "ซีพี-คูโรบูตะ",
                img: itembig8
            },
            {
                title: "ซีพี เกี๊ยวซ่าหมู",
                img: itembig9
            },

        ]
    },
    {
        group: [
            {
                title: "ซีพี เกี๊ยวซ่าไก่",
                img: itembig10
            },
            {
                title: "ซีพี เกี๊ยวกุ้งจักรพรรดิ",
                title2: "ดิบพร้อมปรุง",
                img: itembig11
            },
            {
                title: "ซีพี กุ้งต้ม",
                img: itembig12
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี นักเก็ตไก่คลาสสิค",
                img: itembig13
            },
            {
                title: "ซีพี ไก่จ๊อรสต้นตำรับ",
                img: itembig14
            },
        ]
    }
]

export const productsdataBigpackInMobileSize = [
    {
        group: [

            {
                title: "ซีพี เป็ดพะโล้ต้นตำรับ",
                img: itembig1
            },
            {
                title: "ซีพี เป็ดย่างไหว้เจ้า",
                img: itembig2
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี ขาหมูพะโล้",
                img: itembig3
            },
            {
                title: "ซีพี ไก่ต้ม",
                img: itembig4
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี ไก่ต้มน้ำปลา",
                img: itembig5
            },
            {
                title: "ซีพี ขาหมูและคากิพะโล้",
                img: itembig6
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี ขาหมูเยอรมัน หมูดำ",
                title2: "ซีพี-คูโรบูตะ",
                img: itembig7
            },
            {
                title: "ซีพี หมูกรอบชาชู หมูดำ",
                title2: "ซีพี-คูโรบูตะ",
                img: itembig8
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี เกี๊ยวซ่าหมู",
                img: itembig9
            },
            {
                title: "ซีพี เกี๊ยวซ่าไก่",
                img: itembig10
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี เกี๊ยวกุ้งจักรพรรดิ",
                title2: "ดิบพร้อมปรุง",
                img: itembig11
            },
            {
                title: "ซีพี กุ้งต้ม",
                img: itembig12
            },
        ]
    },
    {
        group: [
            {
                title: "ซีพี นักเก็ตไก่คลาสสิค",
                img: itembig13
            },
            {
                title: "ซีพี ไก่จ๊อรสต้นตำรับ",
                img: itembig14
            },
        ]
    }

]

