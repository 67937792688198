import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection, query, where, orderBy } from 'firebase/firestore';
import { useEffect, useState } from "react";
import { config_cloud } from "../env"
import { CSVLink } from "react-csv";


const Admin = () => {
    const [data, setData] = useState([])
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const headers = [
        { label: "รูปภาพใบเสร็จ", key: "bills" },
        { label: "วัน/เดือน/ปี เกิด", key: "birth_date" },
        { label: "อีเมล", key: "email" },
        { label: "ชื่อ / สกุล", key: "fullname" },
        { label: "ห้างสรรพสินค้า", key: "market_name" },
        { label: "หมายเลขโทรศัพท์", key: "telephone" },
        { label: "วันที่เข้าร่วมกิจกรรม", key: "created" },
        { label: "จำนวนเงินทั้งหมด", key: "total_price" }
    ];

    useEffect(() => {
        fetchData()
    }, [])
    const fetchData = async () => {

        const defaultProject = await initializeApp(config_cloud);
        const db = await getFirestore(defaultProject)
        try {

            const citiesRef = collection(db, "users");
            const q = query(citiesRef, orderBy("created", "asc"));
            console.log(citiesRef)
            const querySnapshot = await getDocs(q);
            console.log(querySnapshot)
            querySnapshot.forEach((doc) => {
                let rewrite = {
                    bills: doc.data().bills.join('\n\n'),
                    birth_date: doc.data().birth_date,
                    email: doc.data().email,
                    fullname: doc.data().fullname,
                    market_name: doc.data().market_name,
                    telephone: doc.data().telephone,
                    created: doc.data().created,
                    total_price: doc.data().total_price,
                }
                setData((prev) => [...prev, rewrite])
            });

        }
        catch (error) {
            console.log(error)
        }
    }

    const authen = async () => {

        const defaultProject = await initializeApp(config_cloud);
        const db = await getFirestore(defaultProject)
        try {
            const authRef = collection(db, "auth");
            const q = query(authRef, where("username", "==", username), where("password", "==", password));
            const querySnapshot = await getDocs(q);
            if(querySnapshot._snapshot.docChanges.length == 0)  {
                alert('โปรดตรวจสอบชื่อผู้ใช้งานหรือรหัสผ่าน')
                return ;
            }
            querySnapshot.forEach((doc) => {
                setIsLoggedIn(true)
            });

        }
        catch (error) {
            console.log(error)

        }
    }

    return (
        <div className="container mt-5 bg-dark text-white">
            <div className="row">
                <div className="col-12  vh-100 border d-flex align-items-center justify-content-center">
                    <div className="row ">
                        <div className="col-12">
                            <h1>
                                การจัดการเอกสาร
                            </h1>
                        </div>
                        {
                            isLoggedIn &&
                            <div className="col-12   p-5 w-100">
                                <h1>
                                    ออกเอกสาร .CSV
                                </h1>
                                <CSVLink headers={headers} data={data} filename={`export_data_${new Date()}.csv`}>
                                    <button className="btn btn-primary w-100 mt-5 w-100">DownLoad</button>
                                </CSVLink>
                            </div>
                        }
                        {
                            !isLoggedIn &&
                            <>
                                <div className="col-12 mt-3">
                                    <label htmlFor="name">
                                        ชื่อผู้ใช้งาน
                                    </label>
                                    <input className="form-control" type="text" name="name" placeholder="ชื่อผู้ใช้งาน" id="name" onChange={(event) => setUsername(event.target.value)} />
                                </div>
                                <div className="col-12 mt-3">
                                    <label htmlFor="password">
                                        รหัสผ่าน
                                    </label>
                                    <input type="password" className="form-control" id="password" placeholder="รหัสผ่าน" onChange={(event) => setPassword(event.target.value)} />
                                    <button className="btn btn-primary mt-5" onClick={() => authen()}>
                                        เข้าสู่ระบบ
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin