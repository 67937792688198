import { useEffect, useState } from "react";
import { Button, Offcanvas, placement } from "react-bootstrap"
import { Link } from "react-router-dom"
import { HiMenu, HiMenuAlt4 } from "react-icons/hi"
import { VscClose } from "react-icons/vsc"
const Sidebar = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);
    const pathName = window.location.pathname

    const handleClickScroll_menu1 = () => {
        const element = document.getElementById('head_menu1');
        if (element) {
            // window.location.href = '/home#head_menu1';
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
          handleClose(false)
        } else {
          setTimeout(() => {
            const element2 = document.getElementById('head_menu1');
              if (element2) {
                  // window.location.href = '/home#head_menu2';
                // 👇 Will scroll smoothly to the top of the next section
                element2.scrollIntoView({ behavior: 'smooth' });
                handleClose(false)
              }
            },100)
        }
    }

    const handleClickScroll_menu2 = () => {
       
      const element = document.getElementById('head_menu2');
        if (element) {
            // window.location.href = '/home#head_menu2';
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
          handleClose(false)
        } else {
          setTimeout(() => {
            const element2 = document.getElementById('head_menu2');
              if (element2) {
                  // window.location.href = '/home#head_menu2';
                // 👇 Will scroll smoothly to the top of the next section
                element2.scrollIntoView({ behavior: 'smooth' });
                handleClose(false)
              }
            },100)
        }
      
      
    }
  
    const handleClickScroll_menu3 = () => {
      handleClose(false)
    }
  
  useEffect(() => {
    
  })

  return (
        <>
            {
                show
                    ?
                     <VscClose size={'40px'} color="#fff" style={{ cursor: 'pointer'}} onClick={handleShow} />
                    :
                    <HiMenu size={'40px'} color="#fff" style={{ cursor: 'pointer'}} onClick={handleShow} />

            }



            <Offcanvas show={show} onHide={handleClose} >
                <Offcanvas.Body style={{ background: 'none' }}>

                    <nav className=" mx-auto text-center mt-5 d-flex flex-column">
                        <Link className={
                            pathName === '/home' || pathName === '/' ?
                                "active-link btn btn-menu mt-3 text-white d-flex align-items-center justify-content-center" : "btn btn-menu text-white d-flex align-items-center justify-content-center mt-3"}
                            to="/home#head_menu1" onClick={handleClickScroll_menu1}>ชุดไหว้ดั้งเดิม
                        </Link>
                        <Link className={
                            pathName === '/home' || pathName === '/' ?
                                "active-link btn btn-menu mt-3 text-white d-flex align-items-center justify-content-center" : "btn btn-menu text-white d-flex align-items-center justify-content-center mt-3"}
                            to="/home#head_menu2" onClick={handleClickScroll_menu2}>ชุดไหว้สะดวกมงคล
                        </Link>
                        <Link className={pathName === '/tradition' ?
                            "active-link btn btn-menu mt-3 text-white d-flex align-items-center justify-content-center" : "btn btn-menu text-white d-flex align-items-center justify-content-center mt-3"}
                            to="/tradition" onClick={handleClickScroll_menu3} >ธรรมเนียมไหว้เจ้า
                        </Link>

                      
                    </nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Sidebar

