import React, { useEffect } from 'react'
import logo from "../assets/images/logo/success.png"

export const createRedirectPage = (params) => {
    return <RedirectPage {...params} />
}

export const RedirectPage = ({ redirectUrl, waiting = 2000 }) => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = redirectUrl
        }, waiting)
    }, [redirectUrl, waiting])

    return (
        <div className="d-flex align-items-center justify-content-center bg-dark text-white vh-100 flex-column">
            <div className='d-block'>
                <img src={logo} alt="สินค้าไหว้เจ้าซีพี แห่งปีเสือทอง 2565" width={'150px'}/>
            </div>
            <p className='mt-3'>
                กำลังทำการไปยังหน้าการซื้อสินค้า...
            </p>
        </div>
    )
}
