import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/Home/Home"
import Tradition from "./components/Tradition/Tradition"
import Campaign from "./components/Campaign/Campaign"
import {
  createRedirectPage
} from "./pages/createRedirect"
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Admin from './pages/admin';
function App() {
  const redirectList = [

    {
      path: "/QR-Makro",
      params: { redirectUrl: "https://chinesenewyear.cpbrandsite.com/" },
    },
    {
      path: "/QR-MT",
      params: { redirectUrl: "https://chinesenewyear.cpbrandsite.com/" },
    },
    {
      path: "/QR-MakroClick",
      params: { redirectUrl: "https://www.makro.pro/" },
    },

    {
      path: "/OnlineShop-MakroClick",
      params: { redirectUrl: "https://www.makro.pro/" },
    },
    {
      path: "/OnlineShop-Lotus",
      params: { redirectUrl: "https://www.lotuss.com/th" }
      // { redirectUrl: "https://shoponline.tescolotus.com/groceries/th-TH/zone/HotDeals/" },
    },
    {
      path: "/OnlineShop-CPFreshmart",
      params: { redirectUrl: "https://cpfreshmartshop.com/สินค้าไหว้" },
    },
    {
      path: "/OnlineShop-All",
      params: { redirectUrl: "https://www.allonline.7eleven.co.th/c/chinese-pre-order/" },
    },

    {
      path: "/OnlineShop-BigC",
      params: { redirectUrl: "https://www.bigc.co.th/" },
    },


    {
      path: "/OnlineShop-GourmetMarket",
      params: { redirectUrl: "https://gourmetmarketthailand.com/th", },
    },
    {
      path: "/OnlineShop-TopsOnline",
      params: {
        // redirectUrl: "https://www.tops.co.th/th/catalog/cp-cny-2022?utm_source=supplier_facebook&utm_medium=brand&utm_campaign=%7Cda%3A20200826%7Cch%3Asupplier_facebook%7Cbr%3Abrand%7Ccm%3Acp-cny-2022&%24uri_redirect_mode=2&_branch_match_id=992343334295635555&_branch_referrer=H4sIAAAAAAAAA2VP7QrCMAx8mvpv3ehERRgiiq9RsqxuZf0IXcvw7c32SxBCyOWOu2TKmZZrXa%2FqRBKIpLNhrm9CHd%2FgXA8465JcN20y0d6FenGt6ypzpEVilHnixd4QMrg4bhNVGD6VapQS7atkr5dYEhrRPpdC5KxJ%2Bg1o%2Bhjnw0Z7M9jiuz5BGPYFgiewY%2BjE%2BTEAB7NX01zUiTFOjP98mOgTE7vHpvIMfg458EslWZ04KhnM2sfBdOoLatih1QABAAA%3D",
        redirectUrl: "https://www.tops.co.th/th/chinese-new-year-2024"
      },
    },
    {
      path: "/OnlineShop-Shopee",
      params: {
        // redirectUrl: "https://shopee.co.th/cpf_online_shop?utm_source=website&utm_medium=banner&utm_campaign=chinesenewyear2023",
        redirectUrl: "https://bit.ly/3HvVODg"
      },
    },
    {
      path: "/OnlineShop-Lazada",
      params: {
        // redirectUrl: "https://www.lazada.co.th/shop/cpf-online?utm_source=website&utm_medium=banner&utm_campaign=chinesenewyear2023",
        redirectUrl: "https://bit.ly/3TWqoxk",
      },
    },
  ];

  return (
    <Router>
      <Routes>
        {redirectList.map(({ path, params }) => {
          return (
            <Route
              key={path}
              path={encodeURI(path)}
              element={createRedirectPage(params)}
            />
          );
        })}

        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/tradition" element={<Tradition />} />
        <Route exact path="/campaign" element={<Campaign />} />
        <Route exact path="/admin" element={<Admin />} />
        {/* <Route exact path="/readmore-article/:id" element={<ArticleDetail />} /> */}
        {/* <Route exact path="/start-learn/level/:id" element={<StartLearnLevel />} /> */}
      </Routes>
    </Router>

  );
}

export default App;