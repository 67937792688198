import MainLayout from "../../mainLayout/MainLayout"
import Underline from "../Light/light"
import "./styles.css"
import upload from "../../assets/images/landing/upload.png"
import plus from "../../assets/images/logo/add.png"
import { useEffect, useState } from "react"
import { config_cloud } from "../../env"
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useForm } from "react-hook-form"
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import Popup from "../Popup/Popup"
import {
    mapMonthObject,
    mapMonth,
    mapDay,
    margets
} from "./Variables"
import { regexExpression } from "../../validate/interface"
import { IoMdArrowDropdown } from "react-icons/io"
const Campaign = () => {

    const [accept, setAccept] = useState(true)
    const [images, setImages] = useState([''])
    const [fullName, setfullName] = useState('')
    const [email, setEmail] = useState('')
    const [telephone, settelePhone] = useState('')
    const [date, setDate] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [mart, setMart] = useState('')
    const [price, setPrice] = useState('')
    const [isHasImg, setIsHasImg] = useState(false)
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm();

    const [popup, setPopup] = useState({
        titles: 'ขอบคุณที่ลงทะเบียนร่วมกิจกรรมค่ะ',
        subTitle: 'เราได้รับข้อมูลของคุณเรียบร้อยแล้ว คุณจะได้รับอีเมลยืนยันการลงทะเบียนค่ะ ทางบริษัทฯ จะทำการสุ่มรายชื่อและประกาศรายชื่อผู้โชคดี \n ในวันที่ 14 กุมภาพันธ์​ 2565',
        status: 'success',
        isShowPopup: false,
        buttonText: 'กลับหน้าแรก'
    })

    const onSubmit = (data) => {
        if (isHasImg) {
            saveData(data)
        }
    };

    useEffect(() => {
        if (images.length == 0) {
            setIsHasImg(false)
        } else {
            if (images.find(item => item === '') === undefined) {
                setIsHasImg(true)
            }
        }
    }, [images])

    const addImages = () => {
        if (images.length < 5) {
            let currentImages = [...images]
            currentImages.push('')
            setImages(currentImages)
        } else {

        }
    }


    const handleUpload = (event, index) => {
        try {
            if(!regexExpression.regexImage.test(event[0].name)) {
                setPopup({
                    titles: 'โปรดดำเนินการอีกครั้ง',
                    subTitle: 'คุณกรอกข้อมูลไม่ถูกต้อง โปรดอัพโหลดไฟล์เป็นรูปภาพเท่านั้น',
                    status: 'failed',
                    isShowPopup: true,
                    buttonText: 'กลับหน้าแรก'
                })
                return ;
            }
            const defaultProject = initializeApp(config_cloud);
            let defaultStorage = getStorage(defaultProject);
            const uploadRef = ref(defaultStorage, `products/${event[0].name}`)

            const uploadTask = uploadBytesResumable(uploadRef, event[0]);

            uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            }, (error) => { console.log(error) }, () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                    const setImage = [...images]
                    setImage[index] = downloadURL
                    setImages(setImage)
                });

            })

        } catch (error) {
            console.log(error)
        }
    };


    const saveData = (data) => {
        if (
            images.length === 0
        ) {

            setPopup({
                titles: 'โปรดดำเนินการอีกครั้ง',
                subTitle: 'คุณกรอกข้อมูลไม่ถูกต้อง ครบถ้วนโปรดตรวจสอบข้อมูลใหม่อีกครั้ง',
                status: 'failed',
                isShowPopup: true,
                buttonText: 'กลับหน้าแรก'
            })
        } else {
            let today = new Date();
            let time = `${today.getHours()}:${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}`

            let created = `${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()} ${today.getMonth() + 1 < 10 ? mapMonthObject[`month0${today.getMonth() + 1}`] :
                mapMonthObject[`month${today.getMonth() + 1}`]} ${today.getFullYear() + 543},${time}`
            let mapData = {
                fullname: data.fullName,
                email: data.email,
                telephone: data.telephone,
                birth_date: `${data.date} ${data.month} ${data.year}`,
                market_name: data.mart,
                total_price: data.price,
                created: created,
                bills: images
            }

            const defaultProject = initializeApp(config_cloud);
            const db = getFirestore(defaultProject)
            setPopup({
                titles: 'ขอบคุณที่ลงทะเบียนร่วมกิจกรรมค่ะ',
                subTitle: 'เราได้รับข้อมูลของคุณเรียบร้อยแล้ว คุณจะได้รับอีเมลยืนยันการลงทะเบียนค่ะ ทางบริษัทฯ จะทำการสุ่มรายชื่อและประกาศรายชื่อผู้โชคดี ในวันที่ 14 กุมภาพันธ์​ 2565',
                status: 'success',
                isShowPopup: true,
                buttonText: 'กลับหน้าแรก'
            })
            try {
                const docRef = addDoc(collection(db, "users"), mapData);
                setImages([])
                setfullName('')
                setEmail('')
                settelePhone('')
                setDate('')
                setMonth('')
                setYear('')
                setMart('')
                setPrice('')
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const routerToHome = () => {
        window.location.href = "/"
    }

    useEffect(() => {
        return () => {
            setPopup({
                titles: 'ขอบคุณที่ลงทะเบียนร่วมกิจกรรมค่ะ',
                subTitle: 'เราได้รับข้อมูลของคุณเรียบร้อยแล้ว คุณจะได้รับอีเมลยืนยันการลงทะเบียนค่ะ ทางบริษัทฯ จะทำการสุ่มรายชื่อและประกาศรายชื่อผู้โชคดี ในวันที่ 14 กุมภาพันธ์​ 2565',
                status: 'success',
                isShowPopup: false,
                buttonText: 'กลับหน้าแรก'
            })
        }
    }, [])


    const watchMonth = watch('month')
    console.log(watchMonth)
    return (
        <MainLayout>
            <Popup
                handleAcceptPopup={() => {
                    popup.status === 'success' ? routerToHome()
                        :
                        setPopup({
                            titles: 'ขอบคุณที่ลงทะเบียนร่วมกิจกรรมค่ะ',
                            subTitle: 'เราได้รับข้อมูลของคุณเรียบร้อยแล้ว คุณจะได้รับอีเมลยืนยันการลงทะเบียนค่ะ ทางบริษัทฯ จะทำการสุ่มรายชื่อและประกาศรายชื่อผู้โชคดี ในวันที่ 14 กุมภาพันธ์​     5',
                            status: 'success',
                            isShowPopup: false,
                            buttonText: 'กลับหน้าแรก'
                        })
                }}
                titles={popup.titles}
                subTitle={popup.subTitle}
                status={popup.status}
                isShowPopup={popup.isShowPopup}
                statusPopup={popup.statusPopup}
                buttonText={popup.buttonText}
            />
            <div className="container-fluid wrapper-campaign">
                <div className="section-title">
                    <h1 className="color-yellow">
                        {/* ลงทะเบียน กิจกรรมลุ้นเฮง */}
                        ประกาศรายชื่อผู้โชคดี
                        <Underline />
                    </h1>
                    <h2 className="text-white text-center pt-5">
                        {/* สายมู ไหว้เจ้าหนุนดวง ลุ้นรับ ดับเบิ้ลเฮง จากของไหว้ซีพี */}
                        ประกาศรายชื่อผู้โชคดีได้รับรางวัลจากกิจกรรม “ลุ้นเฮง”
                    </h2>
                    <h4 className="text-white text-center">
                        ดูดวงฟรีกับกองทัพหมอดูชื่อดัง และ Wallpaper เสริมเฮง
                    </h4>
                    {/* <h4 className="text-white text-center">
                        เพียงนำใบเสร็จซื้อของไหว้ซีพีจากห้างสรรพสินค้า ยอดครบ 1,500 บาทขึ้นไป
                    </h4>
                    <h4 className="text-white text-center">
                        มาลงทะเบียนลุ้นดูดวงกับหมอดูชื่อดัง พร้อมแนะนำวิธีการเสริมมงคลชีวิต
                    </h4> */}

                    <h5 className="text-center  py-3 color-yellow">
                        {/* ระยะเวลาร่วมกิจกรรม: 17 ม.ค. – 4 ก.พ. 2565 | ประกาศผลผู้โชคดี: 14 ก.พ. 2565 */}
                        ประจําวันที่ 14 กุมภาพันธ์ 2565
                    </h5>
                
                </div>    
                <div className="wrapper-register-form col-12 col-lg-10 mx-auto">
                    <h5 className="text-white text-decoration">
                        รางวัลดูดวง “แบบส่วนตัว” กับ หมอคฑา ชินบัญชร
                    </h5>
                    <ul className="text-white pt-3">
                        <li>กรณิศ กมลบรรยงค์ 081 346 25XX</li>
                        <li>นานเฉลิมศักดิ์ เลียงพิบูลย์ 081 658 88XX</li>

                    </ul>

                    <h5 className="text-white text-decoration">
                        รางวัลดูดวง “แบบส่วนตัว” กับ Toktak A4
                    </h5>
                    <ul className="text-white pt-3">
                        <li>ทิฆัมพร สิทธิวงศ์วานิช 089 213 83XX</li>
                        <li>ดนุรัตนา ปิ่นทอง 098 249 55XX</li>
                        <li>นานเฉลิมศักดิ์ เลียงพิบูลย์ 081 658 88XX</li>
                        <li>ชุตินันท์ เรืองรุ่งชัยกุล 084 673 92XX</li>
                        <li>ชริน ชาญวรัชญ์ 062 542 63XX</li>

                    </ul>

                    <h5 className="text-white text-decoration">
                        รางวัลดูดวง “แบบส่วนตัว” กับ Bird Eye View
                    </h5>
                    <ul className="text-white pt-3">
                        <li>ทิพวรรณ มณีท่าโพธิ์ 089 644 06XX</li>
                        <li>พลัชอินท์ เอื้ออนันต์ 081 622 18XX</li>
                        <li>สุธินี เล้าประเสริฐ 098 828 18XX</li>
                        <li>พรรณวดี ประยงค์จินดา 085 890 88XX</li>

                    </ul>

                    <h5 className="text-white text-decoration">
                        รางวัลรับ Wallpaper เสริมเฮง “พิเศษเฉพาะคุณ” จาก Horowall
                    </h5>
                    <ul className="text-white pt-3">
                        <li>ธัญชนก ทองเย็น 096 989 44XX</li>
                        <li>ชัยพร พรชัยอรรถกุล 085 967 46XX</li>
                        <li>ทิฆัมพร สิทธิวงศ์วานิช 089 213 83XX</li>
                        <li>ศศิณฏัฐ์ จึงบุลภรณ์ 086 665 54XX</li>
                        <li>ลัดดาวัลย์ เจริญหิรัญศิลป์ 083 220 22XX</li>
                        <li>พรรณวดี ประยงค์จินดา 085 890 88XX</li>
                        <li>สิริชัย ร่มโพธิ์ธารทอง 081 924 16XX</li>

                    </ul>
                </div>
                <div className="wrapper-register-form col-12 col-lg-10 mx-auto" style={{display:"none"}}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}>


                        <div className="row form-row">
                            <label htmlFor="name" className="col-12 col-lg-2 col-form-label text-white">ชื่อ-นามสกุล *</label>
                            <div className=" col-12 col-lg-10">

                                <input
                                    {...register("fullName", { required: true, pattern: regexExpression.regexTextLimit })}
                                    // onChange={(e) => setfullName(e.target.value)}
                                    type="text" className="form-control input-data" id="name" />
                                {errors['fullName'] && <p className="text-white">โปรดกรอกข้อมูลให้ถูกต้อง</p>}
                            </div>
                        </div>

                        <div className="row form-row">
                            <label htmlFor="email" className="col-12 col-lg-2 col-form-label text-white">อีเมล *</label>
                            <div className=" col-12 col-lg-10">
                                <input
                                    {...register("email", { required: true, pattern: regexExpression.regexEmail })}
                                    // onChange={(e) => setEmail(e.target.value)} 
                                    // value={email} 
                                    type="text" className="form-control input-data w-100" id="email"
                                />
                                {errors['email'] && <p className="text-white">โปรดกรอกข้อมูลให้ถูกต้อง</p>}
                            </div>
                        </div>

                        <div className="row form-row">
                            <label htmlFor="tel" className="col-12 col-lg-2 col-form-label text-white">เบอร์โทรศัพท์มือถือ *</label>
                            <div className=" col-12 col-lg-10">

                                <input
                                    // onChange={(e) => settelePhone(e.target.value)} value={telephone} 
                                    {...register("telephone", { required: true, pattern: regexExpression.regexTel })}

                                    type="number" min={0} className="form-control input-data" id="tel" />
                                {errors['telephone'] && <p className="text-white">โปรดกรอกข้อมูลให้ถูกต้อง <br /> เช่น 0999639992</p>}
                            </div>
                        </div>

                        <div className="row form-row border-bottom pb-4">
                            <label className="col-12 col-lg-2 col-form-label text-white">วันเกิด *</label>
                            <div className=" mt-2 col-12 col-lg-2">

                                <input
                                    // onChange={(e) => setYear(e.target.value)} 
                                    // value={year} 
                                    // min={2000}
                                    {...register("year", {
                                        required: true,
                                        validate: value => value > 2000 || "The passwords do not match"

                                    })}
                                    type="number" className="form-control input-data"
                                    placeholder="ปี พ.ศ." />
                                {errors['year'] && <p className="text-white">โปรดกรอกข้อมูลให้ถูกต้อง
                                    <br />
                                    *เช่น 2539</p>}

                            </div>

                            <div className=" mt-2 col-12 col-lg-2 wrapper-input-data">
                                <select type="text" className="form-control  input-data" name="" id=""
                                    // value={month} onChange={(e) => setMonth(e.target.value)}
                                    {...register("month", { required: true, pattern: regexExpression.regexTextOrWhiteSpace })}
                                >
                                    <option value="">เดือน</option>
                                    {
                                        mapMonth.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }

                                </select>
                                <IoMdArrowDropdown className="input-data-svg" size={'25px'} color="#000" />
                                {errors['month'] && <p className="text-white">โปรดเลือกเดือนเกิด</p>}
                            </div>

                            <div className=" mt-2 col-12 col-lg-2 wrapper-input-data">
                                <select type="text" className="form-control input-data"
                                    {...register("date", { required: true, pattern: regexExpression.regexTextOrWhiteSpace })}
                                    id="" value={date} onChange={(e) => setDate(e.target.value)}>
                                    <option value="">วันที่</option>
                                    {
                                        watchMonth == 'กุมภาพันธ์' ?
                                            mapDay.filter(item => item < 29).map((item, index) => {
                                                return <option key={index} value={item}>{item}</option>
                                            })
                                            :
                                            watchMonth == 'มกราคม' ||
                                                watchMonth == 'มีนาคม' ||
                                                watchMonth == 'มีนาคม' ||
                                                watchMonth == 'พฤษภาคม' ||
                                                watchMonth == 'กรกฎาคม' ||
                                                watchMonth == 'สิงหาคม' ||
                                                watchMonth == 'ตุลาคม' ||
                                                watchMonth == 'ธันวาคม' ?
                                                mapDay.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                                :
                                                watchMonth == 'เมษายน' ||
                                                    watchMonth == 'มิถุนายน' ||
                                                    watchMonth == 'กันยายน' ||
                                                    watchMonth == 'พฤศจิกายน'
                                                    ?
                                                    mapDay.filter(item => item < 31).map((item, index) => {
                                                        return <option key={index} value={item}>{item}</option>
                                                    }) :
                                                    ''
                                    }
                                </select>
                                <IoMdArrowDropdown className="input-data-svg" size={'25px'} color="#000" />
                                {errors['date'] && <p className="text-white">โปรดเลือกวันที่เกิด</p>}
                            </div>
                        </div>

                        <div className="row form-row ">
                            <label htmlFor="tel" className="col-12 col-lg-2 col-form-label text-white">ห้างที่ซื้อสินค้า *</label>
                            <div className=" mt-2 col-12 col-lg-6 wrapper-input-data">
                                <select type="text" className="form-control input-data"
                                    {...register("mart", {
                                        required: true
                                    })}
                                >
                                    <option value="">ห้างที่ซื้อสินค้า</option>
                                    {
                                        margets.map((item, index) => {
                                            return <option key={index} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                                <IoMdArrowDropdown className="input-data-svg" size={'25px'} color="#000" />
                                {errors['mart'] && <p className="text-white">โปรดเลือกห้างที่ซื้อสินค้า</p>}
                            </div>
                            <div className=" mt-2 col-12 col-lg-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <input type="number"
                                        {...register("price", {
                                            required: true,
                                            validate: value => value > 1499 || "The passwords do not match"
                                        })}

                                        id="" className="form-control input-data" placeholder="ยอดซื้อสินค้า (บาท) *"
                                    // onChange={(e) => setPrice(e.target.value)} value={price} 
                                    />
                                </div>
                                {errors['price'] && <p className="text-white">โปรดระบุยอดซื้อสินค้า หรือระบุให้ครบตามจำนวนที่กำหนด</p>}

                            </div>
                        </div>


                        <div className="row form-row ">
                            <label htmlFor="tel" className="col-12 col-lg-2 col-form-label text-white">ถ่ายรูปใบเสร็จ *</label>
                            <div className=" mt-2 col-10">
                                <div className="d-flex w-100">
                                    <div className="container">
                                        <div className="row">
                                            {
                                                images.map((item, index) => {
                                                    return (
                                                        <div className="col-lg-4 col-12 mt-3 " key={index}>
                                                            <input
                                                                type="file"
                                                                className="form-control d-none"
                                                                id={`upload${index}`}
                                                                onChange={(e) => handleUpload(e.target.files, index)} />

                                                            {
                                                                item == '' ?
                                                                    <label htmlFor={`upload${index}`} type="button" style={{
                                                                        position: 'relative',
                                                                        maxWidth: '400px',
                                                                        overflow: 'hidden'
                                                                    }}>
                                                                        <img style={{
                                                                            width: '100%'
                                                                        }} src={upload} className={`d-block me-3 ${item == '' ? '' : 'w-100'}`} />
                                                                    </label> :
                                                                    <label htmlFor={`upload${index}`}>
                                                                        <div
                                                                            style={{
                                                                                position: 'relative',
                                                                                width: '220px',
                                                                                height: '323px',
                                                                                borderRadius: '10px',
                                                                                backgroundSize: 'cover',
                                                                                backgroundPosition: 'center center',
                                                                                overflow: 'hidden',
                                                                                backgroundImage: `url(${item})`
                                                                            }}
                                                                        >

                                                                            {/* <img src={} alt="images" className="w-100"/> */}
                                                                        </div>
                                                                    </label>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                images.length < 5 &&

                                                <div className="col-lg-4"
                                                >
                                                    <div className="d-block d-lg-flex w-100 mt-3"
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => addImages()}>
                                                        <img src={plus} />
                                                        <div className="color-yellow pt-1 " style={{ width: '170px' }}>
                                                            กดเพิ่มใบเสร็จ (สูงสุด 5 ใบ)
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                !isHasImg &&
                                                <p className="text-white">กรุณาอัพโหลดใบเสร็จอย่างน้อย 1 ใบ</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row form-row ">
                            <label className=" mt-2 col-12   col-form-label text-white">
                                <input
                                    type="checkbox" className="mx-3"
                                    onChange={() => setAccept(!accept)}
                                    style={{
                                        transform: 'scale(2)',
                                        outline: 'none',
                                        border: 'none'
                                    }} />

                                ข้าพเจ้ายินยอมให้ข้อมูลแก่ บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) และบริษัทอื่นในเครือฯ เพื่อใช้ประมวลผลและติดต่อสื่อสารกับข้าพเจ้า
                                ทั้งนี้ ข้าพเจ้าได้อ่านและเข้าใจรายละเอียดการใช้และการเก็บรักษาข้อมูลเป็นที่เรียบร้อยแล้ว <a className="pe-2" target="_blank" href="https://www.cpbrandsite.com/privacy">นโยบายส่วนบุคคล</a>
                            </label>
                        </div>
                        <div className="d-flex w-100 wrapper-register">
                            <button disabled={accept} className={!accept ? "btn btn-regis" : "btn btn-regis-disabled"} type="submit">
                                ลงทะเบียนร่วมกิจกรรม
                            </button>
                        </div>
                    </form>
                </div>

                <div className="container wrapper-terms">
                    <div className="section-title">
                        <h1 className="color-yellow">
                            กติกาและเงื่อนไขกิจกรรม
                            <Underline />
                        </h1>
                    </div>
                    <h5 className="text-white text-decoration">
                        กติกาในการร่วมกิจกรรมเพื่อรับของรางวัล
                    </h5>
                    <ol className="text-white pt-3">
                        <li>ผู้ร่วมกิจกรรมจะต้อง ซื้อชุดไหว้เจ้า ซีพี (ที่ร่วมรายการ) เมื่อซื้อครบทุก ๆ 1,500 บาท จะได้รับ 1 สิทธิ์ โดยซื้อผ่านทางออนไลน์ หรือทางห้างสรรพสินค้าที่เข้าร่วมรายการดังนี้ Makro, Lotus’s , CP Freshmart, 7-Eleven, Big C, Gourmet Market, Tops, MaxValu, Golden Place และ Dear Tummy</li>
                        <li>ลงทะเบียนผ่าน https://chinesenewyear.cpbrandsite.com โดยกรอก ชื่อ-นามสกุล, วัน/เดือน/ปีเกิด, เบอร์โทรศัพท์/อีเมล และสถานที่ซื้อสินค้า ตั้งแต่วันที่ 17 มกราคม 2565 จนถึง วันที่ 4 กุมภาพันธ์ 2565</li>
                        <li>ถ่ายรูปใบเสร็จแบบเต็มใบ 1 ใบต่อ 1 รูป (อัพโหลดได้ไม่เกิน 5 รูป)</li>
                        <li>คณะกรรมการจะทำการจับรางวัลผู้โชคดี ในวันที่ 9 กุมภาพันธ์ 2565 จำนวน 100 ท่าน และทำการจับรางวัลรายชื่อสำรอง จำนวน 50 ท่าน (ในกรณีที่มีการสละสิทธิ์ของผู้ที่ได้รางวัล) โดยมีสักขีพยานอยู่ด้วย</li>
                        <li>ทีมงานจะทำการโทรประสานติดต่อผู้โชคดี ทั้ง 100 ท่าน เพื่อทำการยืนยันตัวตนและยืนยันการรับสิทธิ์ ระหว่างวันที่ 9-11 กุมภาพันธ์ 2565 หากเลยวันดังกล่าวแล้ว แต่ยังไม่สามารถติดต่อผู้โชคดีได้จะถือว่าสละสิทธิ์ ของรางวัลจะตกเป็นของผู้ที่มีรายชื่อสำรองทันทีตามลำดับ</li>
                        <li>ทีมงานจะทำการประกาศรายชื่อผู้โชคดี วันที่ 14 กุมภาพันธ์ 2565 พร้อมทั้งส่ง SMS เพื่อเป็นการยืนยันการรับรางวัล และแจ้งช่องทางการติดต่อเพื่อรับของรางวัล หลังจากได้รับ SMS ยืนยัน ผู้โชคดีสามารถติดต่อกลับไปเพื่อทำการนัดหมายรับของรางวัลตามขั้นตอน ในวันและเวลาที่กำหนดใน SMS</li>

                    </ol>

                    <h5 className="text-white text-decoration">
                        ระยะเวลาร่วมกิจกรรม
                    </h5>
                    <ul className="text-white pt-3">
                        <li>
                            ตั้งแต่วันที่ 17 มกราคม 2565 จนถึง วันที่ 4 กุมภาพันธ์ 2565
                            (โดยทางทีมงานจะทำการลุ้นจับฉลากรางวัลในวันที่ 9 กุมภาพันธ์ 2565)
                        </li>
                    </ul>
                    <h5 className="text-white text-decoration">
                        ประกาศรางวัล: วันที่ 14 กุมภาพันธ์ 2565 ผ่านทาง
                    </h5>
                    <ul className="text-white pt-3">
                        <li>Facebook Page (CP Brand): https://www.facebook.com/brandcp</li>
                        <li>Microsite: https://chinesenewyear.cpbrandsite.com</li>
                    </ul>

                    <h5 className="text-white text-decoration">
                        ของรางวัลและการคัดเลือกผู้โชคดี
                    </h5>
                    <p className="text-white">
                        ทีมงานจะทำการพิมพ์รายชื่อทุกท่านที่ลงทะเบียนเข้ามาร่วมกิจกรรม และจะต้องเป็นผู้ที่มีใบเสร็จซื้อชุดไหว้เจ้าซีพี ครบ 1,500 บาท และนำมาทำฉลากตัดใส่ในกล่องใส และคณะกรรมการจะทำการจับฉลากผู้โชคดีที่ทำถูกต้องตามกติกา เพื่อรับของรางวัลทั้งสิ้น จำนวน 100 รางวัล โดยแบ่งของรางวัลดังนี้
                    </p>
                    <ul className="text-white pt-3">
                        <li>รางวัลที่ 1 ดูดวงส่วนตัว กับ หมอคฑา ชินบัญชร	จำนวน 2 รางวัล มูลค่ารางวัลละ 30,000 บาท</li>
                        <li>รางวัลที่ 2 ดูดวงส่วนตัว กับ TokTak A4 	จำนวน 10 รางวัล มูลค่ารางวัลละ 10,000 บาท</li>
                        <li>รางวัลที่ 3 ดูดวงส่วนตัว กับ Bird Eye View จำนวน 38 รางวัล มูลค่ารางวัลละ 1,500 บาท</li>
                        <li>รางวัลที่ 4 Customize Wallpaper by Horowall	จำนวน 50 รางวัล มูลค่ารางวัลละ 300 บาท</li>
                    </ul>
                    <h5 className="text-white text-decoration">
                        ข้อกำหนดและเงื่อนไข
                    </h5>
                    <ol className="text-white">
                        <li>ผู้ร่วมกิจกรรมจะต้องมีสัญชาติไทย อายุ 18 ปีขึ้นไป และอาศัยอยู่ในประเทศไทยเท่านั้น</li>
                        <li>ผู้ร่วมกิจกรรมจะต้องกรอกข้อมูลที่เป็นจริง และสามารถติดต่อได้เท่านั้น เพื่อทางทีมงานสามารถติดต่อกลับในกรณีได้รับของรางวัล มิฉะนั้น จะขอสงวนสิทธิ์รางวัลให้กับผู้ที่มีรายชื่อสำรอง 50 ท่าน ตามลำดับ</li>
                        <li>ของรางวัลไม่สามารถแลกเปลี่ยนมูลค่าเป็นเงินสดได้ และไม่สามารถเปลี่ยนแปลงหรือโอนสิทธิ์ให้ผู้อื่นได้ และไม่มีการจ่ายเงินเป็นส่วนประกอบ</li>
                        <li>บริษัทฯ จะไม่รับภาระและ/หรือรับผิดชอบต่อค่าใช้จ่ายเดินทาง ค่าที่พัก และ/หรือค่าใช้จ่ายอื่นๆ ในการมารับของรางวัลของผู้ได้รับรางวัล ทั้งนี้ ผู้ได้รับรางวัลต้องเป็นผู้รับผิดชอบเองทั้งหมด</li>
                        <li>บริษัทฯ มีสิทธิ์เปลี่ยนแปลง ปฏิเสธ ยกเลิก หรือหยุดกิจกรรมได้โดยชอบธรรม และไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า ทั้งนี้ ผู้เข้าร่วมกิจกรรม ไม่มีสิทธิ์ในการเรียกร้อง หรือร้องขอผลจากการสูญเสียหรือความเสียหายที่เกิดขึ้นทางตรงและทางอ้อม</li>
                        <li>บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงของรางวัลที่มีมูลค่าเท่ากัน รวมถึง เปลี่ยนแปลงรายละเอียดกติกาและเงื่อนไขกิจกรรมได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า </li>
                        <li>ผู้ที่มีส่วนเกี่ยวข้องกับการจัดกิจกรรมในครั้งนี้ ไม่มีสิทธิ์เข้าร่วมกิจกรรมและรับของรางวัลใดๆ ทั้งสิ้น</li>
                        <li>ผู้ที่ได้รับรางวัลยินยอมให้ทาง บริษัทฯ พิมพ์และ/หรือเผยแพร่รายชื่อเพื่อการโฆษณาและประชาสัมพันธ์ธุรกิจ ในปัจจุบันและ/หรือในอนาคตตามที่เห็นสมควร และไม่คิดค่าใช้จ่ายหรือค่าตอบแทนใดๆ เป็นการตอบแทนแต่อย่างใด</li>
                        <li>ผู้เข้าร่วมกิจกรรมได้อ่านและเข้าใจข้อความดีแล้ว ตกลงยินยอมที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขที่ระบุไว้ข้างต้นทุกประการ</li>
                        <li>การตัดสินของคณะกรรมการถือเป็นเด็ดขาดและสิ้นสุด</li>
                    </ol>
                </div>
            </div>
        </MainLayout >
    )
}

export default Campaign